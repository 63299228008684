<template>

  <div class="fragment">

    <div class="order-create__title-horizontal"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta_packageProduct'])"></div>
      <TitleHorizontal
              :value="`${$t('poshta_packageProduct.localization_value.value')} #${index}`"
              :rightBtn="true"
              :rightBtnType="'delete'"
              :rightBtnText="$t('common_DeleteProduct.localization_value.value')"
              @rightBtnClick="$emit('removeProduct')"
      />
    </div>

    <div class="order-create__product custom-row"
         @mouseover="isHovering = true"
         @mouseout="isHovering = false"
         :class="{hover: isHovering}"
    >
      <div class="custom-col">
        <SimpleProductBlock
                :userId="UkrP.data.User.userId !== '' ? UkrP.data.User.userId : -1"
                :simpleProduct="ukrposhtaProduct"
                :orderCurrency="UkrP.data.orderCurrency"
                :nameCyrillic="true"
                :hSCode="UkrP.data.visibleHSCode"
                @removeProduct="UkrP.removeUkrposhtaProduct(index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SimpleProductBlock from "../../../../../../coreComponents/SimpleProductBlock/SimpleProductBlock";
import TitleHorizontal from "../../../../../../coreComponents/TitleHorizontal/TitleHorizontal";

export default {
  name: "UkrposhtaProduct",

  components: {
    TitleHorizontal,
    SimpleProductBlock,
  },

  props: {
    ukrposhtaProduct: Object,
    UkrP: Object,
    index: Number,
  },

  data(){
    return {
      isHovering: false,

      options: [{}],
    }
  },

  mounted() {

  },

  methods: {

  }
}
</script>

<style scoped>

</style>
