<template>
  <div class="order-create__section">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta_PersonalInformation'])"></div>
      {{$t('poshta_PersonalInformation.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_contactName', ])"></div>
        <DefaultInput
                :label="$t('poshta_contactName.localization_value.value')"
                :type="'text'"
                v-bind:class="{'ui-no-valid': PersonalInformation.validation.personalName}"
                :errorTxt="$t(`${PersonalInformation.validationTranslate.personalName}.localization_value.value`)"
                :error="PersonalInformation.validation.personalName"
                v-model="PersonalInformation.information.personalName"
        />
      </div>

      <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_phone', ])"></div>
        <DefaultInput
                :label="$t('common_phone.localization_value.value')"
                :type="'text'"
                v-bind:class="{'ui-no-valid': PersonalInformation.validation.personalPhone}"
                :errorTxt="$t(`${PersonalInformation.validationTranslate.personalPhone}.localization_value.value`)"
                :error="PersonalInformation.validation.personalPhone"
                v-model="PersonalInformation.information.personalPhone"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_email', ])"></div>
        <DefaultInput
                :label="$t('common_email.localization_value.value')"
                :type="'text'"
                v-bind:class="{'ui-no-valid': PersonalInformation.validation.personalEmail}"
                :errorTxt="$t(`${PersonalInformation.validationTranslate.personalEmail}.localization_value.value`)"
                :error="PersonalInformation.validation.personalEmail"
                v-model="PersonalInformation.information.personalEmail"
        />
      </div>
    </div>

    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta_SenderAddress', 'poshta_SenderAddressTooltip'])"></div>
      {{ $t('poshta_SenderAddress.localization_value.value') }}
      <v-popover
              class="site-tooltip"
              offset="5"
              placement="top"
              trigger="hover"

      >
        <TooltipBtn
                class="tooltip-target"

        />
        <template slot="popover">
          <p>
            <b>{{ $t('poshta_SenderAddress.localization_value.value') }}</b>
          </p>
          <p>
            {{$t('poshta_SenderAddressTooltip.localization_value.value')}}
          </p>
        </template>
      </v-popover>
    </div>

    <div class="order-create__section-sub">
      <div class="order-create__row custom-row">
        <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_address', ])"></div>
          <DefaultInput
                  :label="$t('common_address.localization_value.value')"
                  :type="'text'"
                  v-bind:class="{'ui-no-valid': DeliveryAddress.validation.address}"
                  :errorTxt="$t(`${DeliveryAddress.validationTranslate.address}.localization_value.value`)"
                  :error="DeliveryAddress.validation.address"
                  v-model="DeliveryAddress.delivery.address"
          />
        </div>
        <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_city', ])"></div>
          <DefaultInput
                  :label="$t('common_city.localization_value.value')"
                  :options="options"
                  v-bind:class="{'ui-no-valid': DeliveryAddress.validation.city}"
                  :errorTxt="$t(`${DeliveryAddress.validationTranslate.city}.localization_value.value`)"
                  :error="DeliveryAddress.validation.city"
                  v-model="DeliveryAddress.delivery.city"
          />
        </div>
        <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_regions', ])"></div>
          <DefaultInput
                  :label="$t('common_regions.localization_value.value')"
                  :options="options"
                  v-bind:class="{'ui-no-valid': DeliveryAddress.validation.region}"
                  :errorTxt="$t(`${DeliveryAddress.validationTranslate.region}.localization_value.value`)"
                  :error="DeliveryAddress.validation.region"
                  v-model="DeliveryAddress.delivery.region"
          />
        </div>
        <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_country', ])"></div>
          <DefaultSelect
                  :label="$t('common_country.localization_value.value')"
                  :options="options"
                  v-bind:class="{'ui-no-valid': DeliveryAddress.validation.country}"
                  :errorTxt="$t(`${DeliveryAddress.validationTranslate.country}.localization_value.value`)"
                  :error="DeliveryAddress.validation.country"
                  :selected="DeliveryAddress.delivery.country"
                  :optionsLabel="'label'"
                  @change="changeCountry"
          />

          <!--<span class="site-link site-link-alt mt-3 ml-auto wfc	d-flex"-->
                <!--@click="showCustomLimitsPopup"-->
          <!--&gt;-->
						<!--View Custom Limits-->
					<!--</span>-->
        </div>
        <div class="order-create__col custom-col custom-col--16  custom-col--md-25 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_zipCode', ])"></div>
          <DefaultInput
                  :label="$t('common_zipCode.localization_value.value')"
                  :type="'text'"
                  v-bind:class="{'ui-no-valid': DeliveryAddress.validation.zip}"
                  :errorTxt="$t(`${DeliveryAddress.validationTranslate.zip}.localization_value.value`)"
                  :error="DeliveryAddress.validation.zip"
                  v-model="DeliveryAddress.delivery.zip"
          />
        </div>
      </div>
    </div>

    <CustomLimitsPopup
            @close="closeCustomLimitsPopup"
            v-if="isModalCustomLimitsPopup"
    />

  </div>
</template>

<script>

  import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {mixinDetictingMobile} from "../../../../../../mixins/mobileFunctions";
  import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
  import CustomLimitsPopup from "../../../popups/CustomLimitsPopup/CustomLimitsPopup";
  import TooltipBtn from "../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  import {countriesMixin} from "../../../../../../mixins/commonMixins/countriesMixin";


  export default {
    name: "StepOne",
    components: {
      DefaultSelect,
      DefaultInput,
      CustomLimitsPopup,
      TooltipBtn,
    },

    mixins: [mixinDetictingMobile, countriesMixin],

    props: {
      UkrP: Object,
      PersonalInformation: Object,
      DeliveryAddress: Object,
    },

    data() {
      return {
        options: [{}],

        name: 'Kate Washington',
        phone: '+1 384 481 38 90',
        email: 'tatiana_matiukhina@gmail.com',
        address: 'Bryklin str, 9 ofice 293-A',
        city: 'Minneapolis',
        country: '',
        region: '',
        code: '',
        zip: '25778',

        isModalCustomLimitsPopup: false,
      }
    },

    mounted() {
      this.$store.dispatch('fetchCountries').then(() => {
        let countries = this._.toArray(this.$store.getters.getCountries)
        this.options = this.getCountriesForSender(countries)
      })
    },

    methods: {
      showCustomLimitsPopup() {
        this.isModalCustomLimitsPopup = true
      },

      closeCustomLimitsPopup() {
        this.isModalCustomLimitsPopup = false
      },

      changeCountry(val) {
        this.UkrP.data.DeliveryAddress.setCountry(val)
      },

    },
  }
</script>

<style scoped>

</style>
