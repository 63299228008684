

export const setPersonalInfoMixin = {
  methods:{
    setPersonalInfo(user, userProperty = 'contacts', personalInfo = false, delivery = false) {

      if (!user) return

      let userContact

      if(userProperty === 'contacts'){
        userContact = this._.first(user.contacts)
      } else {
        userContact = user.user_personal_contact
      }

      personalInfo.setPersonalItem({
        personalName: userContact.user_full_name,
        personalPhone: userContact.phone,
        personalEmail: userContact.email,
      })

      delivery.setDeliveryItem({
        address: userContact.address,
        city: userContact.city,
        region: userContact.region?.name,
        country:  {
          id: userContact.country.id,
          label: userContact.country?.name,
          code_iso_2: userContact.country?.code_iso_2
        },
        zip: userContact.zip,
      })
    }
  },

}
