<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['common_NewOrder'])"></div>
      <CardLeftBlock
              :name="''"
              :value="''"
              :backgroundImage="'orders'"
      >
        <template slot="body">
          <h2 class="mt-0">{{$t('common_NewOrder.localization_value.value')}}</h2>
          <div class="card-detail-left__date">
            {{ new Date() | moment("DD MMM, YYYY") }}
          </div>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col">
                <router-link :to="$store.getters.GET_PATHS.ukrposhta" class="order-create__head-back">
                  <LinkBack
                          :value="$t('poshta_backTo.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['poshta_createUkrposhta'])"></div>
              {{ $t('poshta_createUkrposhta.localization_value.value') }}
            </div>
            <div class="order-create__head-txt"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['poshta_PleaseInformation'])"></div>
              {{ $t('poshta_PleaseInformation.localization_value.value') }}
            </div>

            <div
                    v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                'poshta_sendingDetails',
                'poshta_recipient',
                'poshta_shippingDetails',
                'poshta_packagingShipping',
                'poshta_confirmation',
              ])"></div>
              <div class="order-create__head-steps"
                   v-if="isCurrentUserRoleUser">
                <ProgressSteps
                        :options="progressStepsSetting"
                        :step="activeStep"
                        @changeStep="stepChange"
                        @nextPageHandler="nextPageHandler"
                />
              </div>
            </div>
          </div>
        </template>

        <template slot="body">

          <div class="order-create__section">
            <div class="custom-row" v-if="isAdmin">
              <div class="custom-col custom-col--33 custom-col--md-100">
                <UserSelect
                        :validationUser="UkrP.validation.user"
                        :validationTxtUser="UkrP.validationTxt.user"
                        :validationTranslateUser="UkrP.validationTranslate.user"
                        :userSelected="UkrP.data.User.user"
                        @changeUser="changeUser"
                        :typeSelect="'users'"
                        :userEmail="true"
                />
              </div>
            </div>
          </div>

          <StepOne v-if="activeStep === 1 || isAdmin"
                   :UkrP="UkrP"
                   :PersonalInformation="UkrP.data.PersonalInformation"
                   :DeliveryAddress="UkrP.data.DeliveryAddress"

          />
          <StepTwo v-if="activeStep === 2 || isAdmin"
                   :UkrP="UkrP"
                   :recipientInformation="UkrP.data.RecipientInformation"
                   :recipientDeliveryAddress="UkrP.data.RecipientDeliveryAddress"
          />
          <StepThree v-if="activeStep === 3 || isAdmin"
                     :UkrP="UkrP"
          />
          <StepFour v-if="activeStep === 4 || isAdmin"
                    :UkrP="UkrP"
                    :packingTypes="packingTypes"
                    :categoryTypes="categoryTypes"
                    @changePackagingType="(item) => $emit('changePackagingType', item)"
                    @changePackingSize="({item, radioItem}) => $emit('changePackingSize', {item, radioItem})"
                    @changePackingInside="({item, radioItem}) => $emit('changePackingInside', {item, radioItem})"
                    @changeCheckItem="({item, checkItem}) => $emit('changeCheckItem', {item, checkItem})"
                    @changeCategoryType="(item) => $emit('changeCategoryType', item)"
          />
          <StepFive v-if="activeStep === 5"
                    :UkrP="UkrP"
                    :PersonalInformation="UkrP.data.PersonalInformation"
                    :DeliveryAddress="UkrP.data.DeliveryAddress"
                    :packingTypes="packingTypes"
                    :categoryTypes="categoryTypes"
                    @changeStep="stepChange"
          />


          <template  v-if="isAdmin">
            <div class="order-create__product-btn add-product-btn-wrap mb-2 mt-3"></div>

            <div class="order-create__row custom-row">
              <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['poshta_shippingCost', ])"></div>
                <DefaultInput
                        :label="$t('poshta_shippingCost.localization_value.value')"
                        :type="'text'"
                        v-model="UkrP.data.deliveryPrice"
                />
              </div>
              <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['poshta_trackingNumber', ])"></div>
                <DefaultInput
                        :label="$t('poshta_trackingNumber.localization_value.value')"
                        :type="'text'"
                        v-model="UkrP.data.trackingNumber"
                />
              </div>
            </div>
          </template>

        </template>
        <template slot="footer">
          <div class="order-create__footer">

            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate(['common_cancel', 'common_Previous'])"></div>
              <router-link :to="$store.getters.GET_PATHS.ukrposhta"
                           class="site-link site-link--alt"
                           v-if="activeStep == 1"
              >
                {{ $t('common_cancel.localization_value.value') }}
              </router-link>
              <span class="site-link site-link--alt"
                    @click="prevPageHandler()"
                    v-if="activeStep != 1"
              >
                 {{ $t('common_Previous.localization_value.value') }}
              </span>
            </div>


            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate(['common_SaveInDrafts', 'common_drafts', 'common_savingInDrafts', 'common_Next', 'common_create'])"></div>
              <MainButton
                      class="order-create__footer-btn-i secondary wfc"
                      v-if="activeStep == 5 || isAdmin"
                      :value="$t('common_SaveInDrafts.localization_value.value')"
                      :tooltip="true"
                      @click.native="$emit('create', true)"
              >
                <template slot="tooltip">
                  <p>
                    <b>{{ $t('common_drafts.localization_value.value') }}</b>
                  </p>
                  <p>
                    {{ $t('common_savingInDrafts.localization_value.value') }}
                  </p>
                </template>
              </MainButton>


              <MainButton
                      class="order-create__footer-btn-i"
                      :value="$t('common_Next.localization_value.value')"
                      @click.native="nextPageHandler()"
                      v-if="activeStep !== progressStepsSetting.length && $store.getters.getIsAdminRights === 'user'"
              />

              <MainButton
                      v-else
                      class="order-create__footer-btn-i wfc"
                      :value="$t('common_create.localization_value.value')"
                      @click.native="$emit('getDeliveryPrice')"
              />
                      <!--@click.native="changePayOrderPopup(true)"-->
            </div>

          </div>
        </template>
      </CardRightBlock>

    </div>

  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import MainButton from "../../../../../../components/UI/buttons/MainButton/MainButton";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
  import ProgressSteps from "../../../../../UI/progress/ProgressSteps/ProgressSteps.vue";
  import StepOne from "../../components/StepOne/StepOne";
  import StepTwo from "../../components/StepTwo/StepTwo";
  import StepThree from "../../components/StepThree/StepThree";
  import StepFive from "../../components/StepFive/StepFive";
  import {tabsMixins} from "../../../../../../mixins/creationPageMixins/tabsMixins";
  import UserSelect from "../../../../../coreComponents/UserSelect/UserSelect";
  import StepFour from "../../components/StepFour/StepFour";
  import {ukrposhtaOrdersMixins} from "../../../../../../mixins/internalOrdersMixins/ukrposhtaOrdersMixin";
  import {hsCodeMixin} from "../../../../../../mixins/commonMixins/hsCodeMixin";
  import {vatNumberMixin} from "../../../../../../mixins/commonMixins/vatNumberMixin";
  import {orderCurrencyMixin} from "../../../../../../mixins/commonMixins/orderCurrencyMixin";
  import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";

  export default {
    name: "UkrposhtaCreationSection",

    components: {
      DefaultInput,
      UserSelect,
      CardLeftBlock,
      MainButton,
      CardRightBlock,
      LinkBack,
      ProgressSteps,
      StepOne,
      StepTwo,
      StepThree,
      StepFour,
      StepFive,
    },

    mixins: [
      tabsMixins,
      ukrposhtaOrdersMixins,
      hsCodeMixin,
      vatNumberMixin,
      orderCurrencyMixin,
    ],

   watch: {
     selectedCountry: function(country){
       this.$store.dispatch('getCountryDataById', country.id).then(() => {
         let countryWithCountryGroups = this.$store.getters.getCountryGroup

         this.UkrP.setOrderCurrency(this.getOrderCurrencyByCountry(countryWithCountryGroups))
         // this.UkrP.setVisibleHSCode(this.getHSCodePermissionsByCountry(countryWithCountryGroups))

         // hs code for all countries
         this.UkrP.setVisibleHSCode(true)

         this.UkrP.setVisibleAndIsVatNumber(this.getVatNumberPermissionsByCountry(countryWithCountryGroups))

         this.$emit('changeRecipientCountry')
       }).catch(error => this.createErrorLog(error))
     },
   },

    props: {
      UkrP: Object,
      packingTypes: Object,
      categoryTypes: Object,
      selectedCountry: {Object, String},
    },

    data() {
      return {
        name: '',
        name2: '',
        valueQuantity: '',
        valueSum: '',
        valueSumDisable: '',

        payOrderPopup: false,

        progressStepsSetting: [
          this.$t('poshta_sendingDetails.localization_value.value'),
          this.$t('poshta_recipient.localization_value.value'),
          this.$t('poshta_shippingDetails.localization_value.value'),
          this.$t('poshta_packagingShipping.localization_value.value'),
          this.$t('poshta_confirmation.localization_value.value'),
        ],
        activeStep: 1,

        files: [],
        showingUpc: false,

        options: [{}],
      }
    },

    methods: {
      changePayOrderPopup(val) {
        this.payOrderPopup = val
      },
    }
  }
</script>

<style lang="scss">

  .product-progress-steps {
    max-width: 625px;
  }

</style>
