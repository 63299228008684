<template>
  <div class="fragment">
    <UkrposhtaTableUser
            :countFilterParams="countFilterParams"
            :filterGetParams="filterGetParams"
            @createGroupF103A="checkCreateF103A"
            @resetFilter="resetFilter"
            @showMore="showMore"
            @changeFilter="changeFilter"
            @reload="reload"
    />
  </div>
</template>

<script>
  import UkrposhtaTableUser from "./UkrposhtaTableUser/UkrposhtaTableUser";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {UKRPOSHTA_DELIVERY_SERVICE} from "../../../../../staticData/staticVariables";

  export default {
    name: "UkrposhtaTable",
    components: {
      UkrposhtaTableUser,
    },

    mixins: [routeFilter],

    watch: {
      loadUserAuthorizedData() {
        if(!this.isUkrainian && !this.isAdmin) {
          this.$router.push(this.$store.getters.GET_PATHS.notFound)
        }
      }
    },

    data(){
      return {
        forPage: this.$store.getters.getOrdersInternalForPage,
        page: 1,
        onePage: 1,

        filterId: '',
        filterUserId: '',
        filterUserName: '',
        filterDate: [],
        // filterPayPalTransaction: '',
        filterTrackingNumber: '',
        filterShippingCost: '',

        filterRecipientProducts: '',

        filterRecipientName: '',
        filterRecipientPhone: '',
        filterRecipientEmail: '',
        filterRecipientAddress: '',
        filterRecipientCity: '',
        filterRecipientRegion: '',
        filterRecipientCountry: '',
        filterRecipientCountryId: '',
        filterRecipientZip: '',

        filterShipped: [],

        countFilterParams: 0,
        filterGetParams: {},
      }
    },

    mounted() {

      if(this.loadUserAuthorizedData && (!this.isUkrainian && !this.isAdmin)) {
        this.$router.push(this.$store.getters.GET_PATHS.notFound)
      }

      this.filter()
    },

    beforeDestroy() {
      this.$store.commit('setOrdersInternal',{ordersInternal: []})
      this.$store.commit('setOrdersInternalCommonList',{ordersInternalCommonList: []})
    },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,

          id: this.$route.query.id,
          userId: this.$route.query.userId,
          userName: this.$route.query.userName,
          date: this.$route.query.date,
          payPalTransaction: this.$route.query.payPalTransaction,
          trackingNumber: this.$route.query.trackingNumber,
          shippingCost: this.$route.query.shippingCost,
          shipped: this.$route.query.shipped,

          recipientProducts: this.$route.query.recipientProducts,

          recipientName: this.$route.query.recipientName,
          recipientPhone: this.$route.query.recipientPhone,
          recipientEmail: this.$route.query.recipientEmail,
          recipientAddress: this.$route.query.recipientAddress,
          recipientCity: this.$route.query.recipientCity,
          recipientRegion: this.$route.query.recipientRegion,
          recipientCountry: this.$route.query.recipientCountry,
          recipientCountryId: this.$route.query.recipientCountryId,
          recipientZip: this.$route.query.recipientZip,
        }
      },

      refreshTable() {
        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextOrdersInternalPage', true)
        this.$store.dispatch('fetchOrdersInternal', url).then(() => {
          this.$store.commit('setNextOrdersInternalPage', false)
          this.$store.commit('setGlobalUpdateTable', false)
        })


        this.checkCountFilter(['userName', 'recipientCountry'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        this.generateFilterQueryParams(
          myQuery,
          {

            filterUserId: 'ointUserId',
            filterId: 'ointId',
            filterPayPalTransaction: 'byTransactionNumber',
            filterTrackingNumber: 'ointTrackingNumber',
            filterShippingCost: 'ointDeliveryPrice',

            filterRecipientProducts: 'ointProductName',
            filterRecipientName: 'ointRecipientFullName',
            filterRecipientPhone: 'ointRecipientPhone',
            filterRecipientEmail: 'ointRecipientEmail',
            filterRecipientAddress: 'ointRecipientAddress',
            filterRecipientCity: 'ointRecipientCity',
            filterRecipientRegion: 'ointRecipientState',
            filterRecipientCountryId: 'ointRecipientCountryId',
            filterRecipientZip: 'ointRecipientZip',

          },
        )

        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn(
            'ointBetweenCreatedAt',
            date
          )
        }

        if (this.filterShipped.length > 0) {
          let date = this.generateDateForFilter(this.filterShipped, 'YYYY-MM-DD')

          myQuery.whereIn(
            'ointBetweenShippedAt',
            date
          )
        }


        myQuery.where('ointDeliveryServiceId', UKRPOSHTA_DELIVERY_SERVICE.id)

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      checkCreateF103A() {
        let text = {
          title: 'poshta_CreateF103A',
          txt: '',
          yes: 'common_yes',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          this.createGroupF103A()

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

      createGroupF103A() {
        let exportIds = this.getCheckedRows('row-name')

        if(exportIds.length === 0) return

        let ids = []
        exportIds.map(id => {
          ids.push(parseInt(id))
        })

        this.$store.dispatch('createInternalGroupF103A', {ids: ids}).then(response => {
          if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordChanged')
              this.reload()
              break
            }
            /**
             * Validation Error
             */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
            /**
             * Undefined Error
             */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }

        })


      },

    }

  }
</script>

<style scoped>

</style>
