<template>
  <div class="ukrposhta-btn">
    <div class="ukrposhta-btn__ico"></div>
  </div>
</template>

<script>
  export default {
    name: "UkrposhtaBtn",
    components: {

    },
  }
</script>

<style lang="scss">
  @import "../../../../scss/colors";
  @import "../../../../scss/mixins/mixins";


  .ukrposhta-btn{
    cursor: pointer;
    transition: .15s;

    /*&:hover{*/
      /*transform: scale(1.1);*/
    /*}*/

    &__ico{
      display: block;
      width: 16px;
      height: 24px;
      background: url("../../../../assets/img/common/point.png") center/contain no-repeat;
    }
  }
</style>
