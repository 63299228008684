<template>
  <modal
      @close="$emit('close')"
      class="custom-popup"
  >
    <template slot="header">
      Custom Limits
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="section-label">
          United States of America
        </div>
        <p class="fsz14">
          Variation method FOB (declared value, excluding shipping and insurance).
        </p>
        <div class="section-label mb-1">
          Custom’s Duty
        </div>
        <p class="fsz14">
          From 0 to 37.5% of the amount of FOB. With an average value of 5.63%
        </p>
        <div class="section-label mb-1">
          Sales Tax
        </div>
        <p class="fsz14">
          Sales tas is not levied on imports
        </p>
        <div class="section-label mb-1">
          Threshold for duty-free import
        </div>
        <p class="fsz14">
          Parcels with FOB value (declared value, excluding shipping and insurance) up to $800 are exempted from customs duties
        </p>
      </div>
    </template>
    <template slot="footer">
      <div class="d-flex align-items-center mt-4">
        <span
            class="site-link site-link--alt buy-label__btn-i mr-3"
            @click="$emit('close')"
        >
          View Source
        </span>

        <MainButton
            :value="'Clear, thanks!'"
            class="buy-label__btn-i wfc "
            @click.native="$emit('close')"
        />
      </div>
    </template>
  </modal>

</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'

  export default {
    name: "CustomLimitsPopup",
    components: {
      Modal,
      MainButton,
    },

  }

</script>

<style lang="scss">

  @import "../../../../../scss/mixins/mixins";

  .wrong-orders-for-popup .modal-component__inner{
    max-width: 570px;
  }

  @include for-550{
    .wrong-orders-for-popup .buy-label__btn{
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start!important;
    }

    .wrong-orders-for-popup .buy-label__btn-i.mr-3{
      margin-bottom: 15px;
    }
  }

</style>
