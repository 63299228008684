<template>
  <div class="fragment">
    <template v-if="$store.getters.getOrdersInternal.length > 0 && $store.getters.getOrdersInternalLoading !== true">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'poshta_number',
          'poshta_Created',
          'common_user',
          'poshta_PayPalTransaction',
          'poshta_Shipping',
          'poshta_trackingNumber',
          'poshta_itemNames',
          'poshta_Shipped',
          'common_manage',
        ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th></th>
            <th>{{$t('poshta_number.localization_value.value')}}</th>
            <th>{{$t('poshta_Created.localization_value.value')}}</th>
            <th v-if="isAdmin"> {{$t('common_user.localization_value.value')}}</th>
            <th>{{$t('poshta_PayPalTransaction.localization_value.value')}}</th>
            <th>{{$t('poshta_Shipping.localization_value.value')}}, ₴</th>
            <th>{{$t('poshta_trackingNumber.localization_value.value')}}</th>
            <th>{{$t('poshta_itemNames.localization_value.value')}}</th>
            <th>{{$t('poshta_Shipped.localization_value.value')}}</th>
            <th width="100%" v-if="isCurrentUserRoleUser"></th>
            <th>{{$t('common_manage.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getOrdersInternal" :key="index">
            <td>
              <DefaultCheckbox
                      class="mt-1"
                      :selectedNow="selectedNow"
                      :dataValue="item.id"
              />
            </td>
            <td>
              <div class="table-row">
                <router-link
                        class="table-link btn-style"
                        :to="$store.getters.GET_PATHS.ukrposhta + '/show/' + item.id"
                >
                  #{{item.id}}
                </router-link>
              </div>
            </td>
            <td>
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </td>

            <td v-if="isAdmin">
              <TableUserColumn
                      :item="item"
              />
            </td>

            <td>
              <ValueHelper
                      v-if="item.payment_transaction"
                      :deep="'payment_transaction.transaction_id'"
                      :value="item"
              />
              <ValueHelper
                      v-else
                      :deep="'order_external.payment_transaction.transaction_id'"
                      :value="item"
              />
            </td>
            <td>
              <ValueHelper
                      :value="item.delivery_price"
              />
            </td>
            <td>
              <div class="d-flex align-items-center">
                <UkrposhtaBtn v-if="item.tracking_number" class="mr-1"/>
                <InternalTrackingNumber
                        :item="item"
                        @reload="$emit('reload')"
                />
              </div>
            </td>
            <td>
              <template v-if="item.products.length > 0">
                <v-popover
                        class="site-tooltip"
                        offset="5"
                        placement="top"
                        trigger="hover"
                >
                  <div class="comment-row">
                    {{item.products[0]["name"]}}
                  </div>
                  <template slot="popover">
                    <p
                            v-for="(itemProduct, indexProduct) in item.products"
                            :key="indexProduct">
                      {{itemProduct.name}}
                    </p>
                  </template>
                </v-popover>
              </template>
            </td>
            <td>
              {{ item.shipped_at | moment("DD MMM, YYYY") }}
            </td>
            <td width="100%" v-if="isCurrentUserRoleUser"></td>
            <td>
              <div class="table-right table-manage-list table-manage-list--small"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                  'common_edit',
                  'common_delete',
                  'common_copy',
                  'common_buyLabel',
                  'common_GetLabel',
                  'common_GetF103A',
                ])"></div>

                <ManagerButton
                        class="w215"
                        @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random())"
                        :key="$store.getters.getGlobalUpdateManagerButton"
                >
                  <template slot="item"
                            v-if="(INTERNAL_STATUSES.COMPLETE.id !== item.order_internal_status_id || isAdmin)"
                  >
                    <LinkButton
                            :value="$t('common_edit.localization_value.value')"
                            :type="'edit'"
                            :link="$store.getters.GET_PATHS.ukrposhta + '/' + item.id"
                    />
                  </template>

                  <template slot="item"
                            v-if="INTERNAL_STATUSES.COMPLETE.id !== item.order_internal_status_id || isAdmin"
                  >
                    <LinkButton
                            :value="$t('common_delete.localization_value.value')"
                            :type="'delete'"
                            @click.native="removeOrder(item.id)"
                    />
                  </template>

                  <template slot="item"
                  >
                    <LinkButton
                            :value="$t('common_copy.localization_value.value')"
                            :type="'delete'"
                            @click.native="copyOrder(item.id)"
                    />
                  </template>

                  <template slot="item"
                            v-if="INTERNAL_STATUSES.COMPLETE.id !== item.order_internal_status_id"
                  >
                    <LinkButton
                            :value="$t('common_buyLabel.localization_value.value')"
                            :type="'dog'"
                            @click.native="getDeliveryPriceFromTable(item.id)"
                    />
                  </template>

                  <!--<template slot="item"-->
                            <!--v-if="INTERNAL_STATUSES.COMPLETE.id === item.order_internal_status_id"-->
                  <!--&gt;-->
                    <!--<LinkButton-->
                            <!--:value="$t('common_GetLabel.localization_value.value')"-->
                            <!--:type="'docs'"-->
                            <!--@click.native="getLabel(item.id)"-->
                    <!--/>-->
                  <!--</template>-->

                  <template slot="item"
                            v-if="INTERNAL_STATUSES.COMPLETE.id === item.order_internal_status_id"
                  >
                    <LinkButton
                            :link="$store.getters.GET_PATHS.getPdfFile + '/' + getUkrposhtaLabelUUID(item.file_entries)"
                            :target="'_blank'"
                            :value="$t('common_GetLabel.localization_value.value')"
                            :type="'docs'"
                    />
                  </template>

                  <template slot="item"
                            v-if="item.group"
                  >
                    <LinkButton
                            :link="$store.getters.GET_PATHS.getPdfFile + '/' + getUkrposhtaF103AUUID(item.group.file_entries)"
                            :target="'_blank'"
                            :value="$t('common_GetF103A.localization_value.value')"
                            :type="'docs'"
                    />
                  </template>
                </ManagerButton>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getOrdersInternal"
                 :key="index"
            >
              <UkrposhtaTableMobile
                      :item="item"
                      :selectedNow="selectedNow"
                      @tableCreateLabel="getDeliveryPriceFromTable(item.id)"
                      @removeOrder="removeOrder(item.id)"
                      @copyOrder="copyOrder(item.id)"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                    v-if="$store.getters.getOrdersInternalCommonList.next_page_url !== null && $store.getters.getOrdersInternal.length > 0"
                    @click.native="$emit('showMore')"
                    v-bind:class="{'disabled-btn' : $store.getters.getNextOrdersInternalPage}"
                    :count="$store.getters.getOrdersInternalCommonList.total - $store.getters.getOrdersInternalForPage < $store.getters.getOrdersInternalForPage ?
                  $store.getters.getOrdersInternalCommonList.total - $store.getters.getOrdersInternalForPage:
                  $store.getters.getOrdersInternalForPage"
            />
          </div>
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getOrdersInternalLoading === false && $store.getters.getOrdersInternal.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>

    <!--<ProductsToWarehouse @close="closePopup" v-if="isModalProductsToWarehouse"/>-->


    <PayOrderPopup
            v-if="payOrderPopup"
            :orderPrice="orderPrice"
            :disableBtn="$store.getters.getNovaPoshtaBtn || $store.getters.getUkrPoshtaBtn"
            :currency="'UAH'"
            :generateDocsBtn="true"
            @close="changePayOrderPopup(false)"
            @createLabel="createLabel(orderId, true)"
    />
  </div>
</template>

<script>
  // import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  // import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  // import ExportBtn from '../../../../../../coreComponents/Export/Export'
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  // import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import UkrposhtaBtn from "../../../../../../coreComponents/Buttons/UkrposhtaBtn/UkrposhtaBtn";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  import {ukrposhtaOrdersTableMixins} from "../../../../../../../mixins/internalOrdersMixins/ukrposhtaOrdersMixin";
  import {INTERNAL_STATUSES} from "../../../../../../../staticData/staticVariables";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import PayOrderPopup from "../../../../../../coreComponents/Popups/PayOrderPopup/PayOrderPopup";
  import {
    UkrposhtaCategoryTypes,
    UkrposhtaCheckboxItems,
    UkrposhtaPackageTypes
  } from "../../../../../../../staticData/ukrposhtaStatic";
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import UkrposhtaTableMobile from "./UkrposhtaTableMobile/UkrposhtaTableMobile";
  import InternalTrackingNumber
    from "../../../../../../coreComponents/TableComponents/InternalTrackingNumber/InternalTrackingNumber";
  import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  // import TooltipBtn from "../../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  // import ProductsToWarehouse from "../../../../popups/ProductsToWarehouse/ProductsToWarehouse";


  export default {
    name: "UkrposhtaTable",

    components: {
      TableUserColumn,
      InternalTrackingNumber,
      DefaultCheckbox,
      UkrposhtaTableMobile,
      PayOrderPopup,
      NoResult,
      ValueHelper,
      // TooltipBtn,
      // MainButton,
      // DefaultInput,
      ShowMore,
      // ExportBtn,
      LinkButton,
      ManagerButton,
      // DefaultCheckbox,
      UkrposhtaBtn,
    },

    mixins: [mixinDetictingMobile, ukrposhtaOrdersTableMixins],

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
    },

    data() {
      return {
        orderId: -1,
        showContent: false,

        INTERNAL_STATUSES: INTERNAL_STATUSES,

        packingTypes: JSON.parse(JSON.stringify(UkrposhtaPackageTypes)),

        checkboxItems: UkrposhtaCheckboxItems,

        categoryTypes: UkrposhtaCategoryTypes,

        payOrderPopup: false,

        orderPrice: -1,

      }
    },

    methods: {

      tableCreateLabel(item) {
        this.orderId = item.id
        this.getDeliveryPriceFromTable(item.id)
      },

      getLabel(id) {
        this.$store.dispatch('getUkrPoshtaLabel', id).then((response) => {

          let data = this.getRespData(response)
          this.globalOpenPdfBase64(data['fileContentBase64'])
        })
      },




      removeOrder(id) {
        let text = {
          title: 'poshta_DeleteBelposhtaOrder',
          txt: '',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          this.$store.dispatch('deleteOrdersInternal', id).then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              let orders = this.$store.getters.getOrdersInternal
              orders.map((item, index) => {
                if (item.id === id) {
                  orders.splice(index, 1)
                }
              })

              this.openNotify('success', 'common_notificationRecordDeleted')
            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },
    }

  }

</script>

<style scoped lang="scss">

  .site-table thead th:first-child,
  .site-table tbody td:first-child {
    padding-right: 10px;
  }

  .site-table thead th:last-child,
  .site-table tbody td:last-child {
    padding-right: 10px;
  }

  .small-table th,
  .small-table td {
    white-space: nowrap;
  }

  .w215 {
    width: 215px;

    @media (max-width: 1740px) {
      width: 100px;
    }
  }

  .ukr-poshta-item-name {
    @media (max-width: 1740px) {
      max-width: 100px;
      overflow: hidden;
      position: relative;
      text-overflow: ellipsis;
    }
  }
</style>
