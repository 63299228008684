<template>
  <div class="order-create__row custom-row">

    <div class="order-create__col custom-col custom-col--66 custom-col--md-100"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
                        'product_ProductName',
                      ])"></div>
      <SearchSelect
          :options="$store.getters.getSimpleProductProfile"
          :label="$t('product_ProductName.localization_value.value')"
          v-bind:class="{'ui-no-valid': simpleProduct.validation.name || simpleProduct.validation.productObject}"
          :errorTxt="$t(`${simpleProduct.validation.name ? simpleProduct.validationTranslate.name : simpleProduct.validationTranslate.productObject}.localization_value.value`)"
          :error="simpleProduct.validation.name || simpleProduct.validation.productObject"
          :selected="simpleProduct.data.productObject"
          :optionsLabel="'name'"
          :functionSearch="functionSearchSimpleProduct"
          @change="changeSimpleProduct"
      />
    </div>
    <div class="order-create__col custom-col custom-col--33 mb-0 custom-col--md-100" v-if="nameCyrillic"></div>

    <div class="order-create__col custom-col custom-col--33 custom-col--md-100"
         v-if="nameCyrillic"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
                        'product_ProductNameCyrillic',
                      ])"></div>
      <DefaultInput
          :label="$t('product_ProductNameCyrillic.localization_value.value')"
          :type="'text'"
          v-bind:class="{'ui-no-valid': simpleProduct.validation.nameCyrillic}"
          :errorTxt="serverError ? simpleProduct.validationTxt.nameCyrillic : $t(`${simpleProduct.validationTranslate.nameCyrillic}.localization_value.value`)"
          :error="simpleProduct.validation.nameCyrillic"
          v-model="simpleProduct.data.nameCyrillic"
      />
    </div>

    <div class="order-create__col custom-col custom-col--33 custom-col--md-100"
         v-if="hSCode"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
                        'proform_hSCodeCode',
                      ])"></div>
      <DefaultInput
          :label="$t('proform_hSCodeCode.localization_value.value')"
          :type="'text'"
          v-bind:class="{'ui-no-valid': simpleProduct.validation.hSCode}"
          :errorTxt="serverError ? simpleProduct.validationTxt.hSCode : $t(`${simpleProduct.validationTranslate.hSCode}.localization_value.value`)"
          :error="simpleProduct.validation.hSCode"
          v-model="simpleProduct.data.hSCode"
      />
    </div>

    <div v-if="hSCode" class="order-create__col custom-col custom-col--33 mb-0 custom-col--md-100"></div>


    <div class="order-create__col custom-col custom-col--16 custom-col--md-25 custom-col--sm-50"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
                        'common_ItemsQty',
                      ])"></div>

      <InputQuantity
          :label="$t('common_ItemsQty.localization_value.value')"
          v-bind:class="{'ui-no-valid': simpleProduct.validation.count}"
          :errorTxt="$t(`${simpleProduct.validationTranslate.count}.localization_value.value`)"
          :error="simpleProduct.validation.count"
          v-model="simpleProduct.data.count"
          @change="changeValueQuantity"
      />
    </div>

    <div class="order-create__col custom-col custom-col--16 custom-col--md-25 custom-col--sm-50"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
                        'common_PricePerItem',
                      ])"></div>
      <InputSum
          :label="$t('common_PricePerItem.localization_value.value')"
          :icoType="orderCurrency"
          :numeric="true"
          :placeholder="'0'"
          v-bind:class="{'ui-no-valid': simpleProduct.validation.price}"
          :errorTxt="$t(`${simpleProduct.validationTranslate.price}.localization_value.value`)"
          :error="simpleProduct.validation.price"
          v-model="simpleProduct.data.price"
          @input="changeValueSum"

      />
    </div>

    <div class="order-create__col custom-col custom-col--16 custom-col--md-25 custom-col--sm-50 position-relative"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
                        'common_totalSum',
                      ])"></div>

      <InputSum
          :label="$t('common_totalSum.localization_value.value')"
          :icoType="'dollar'"
          v-model="simpleProduct.data.totalSum"
          :disabled="true"
      />


      <div class="order-create__product-add-to-profile"
           v-if="$store.getters.getIsAdminRights === 'user' &&
                 (simpleProduct.data.name  !== '' || wasChangedProductData)"
           @click="addToProfile">

        <template>
          <v-popover
              class="site-tooltip"
              offset="5"
              placement="top"
              trigger="hover"
          >
                    <span>
                      <AddToProfileIco/>
                    </span>
            <template slot="popover">
              {{$t('common_AddProfile.localization_value.value')}}
            </template>
          </v-popover>
        </template>
      </div>
    </div>

  </div>
</template>

<script>
import SearchSelect from "../../UI/selectiones/SearchSelect/SearchSelect";
// import DefaultInput from "../../UI/inputs/DefaultInput/DefaultInput";
import AddToProfileIco from '../../../../public/img/UI-group/add-to-profile-ico.svg?inline'
import InputSum from "../../UI/inputs/InputSum/InputSum";
import InputQuantity from "../../UI/inputs/InputQuantity/InputQuantity";
import DefaultInput from "../../UI/inputs/DefaultInput/DefaultInput";
export default {
  name: "SimpleProductBlock",

  components: {
    DefaultInput,
    // DefaultInput,
    SearchSelect,
    AddToProfileIco,
    InputSum,
    InputQuantity,
  },

  props: {
    simpleProduct: Object,
    orderCurrency: String,
    userId: {
      type: Number,
    },
    nameCyrillic: {
      type: Boolean,
      default: false,
    },
    hSCode: {
      type: Boolean,
      default: false,
    },
    hideAddProfile: {
      type: Boolean,
      default: false
    },
  },

  watch: {
    userId: function (){
      if((this.userId > -1) || this.isAdmin) {
        this.getSimpleProduct()
      }
    },
    // loadUserAuthorizedData: function() {
    //   this.getSimpleProduct()
    // },

    getHS() {
      console.log(this.simpleProduct.validation.hSCode);
    }
  },

  computed: {
    wasChangedProductData() {
      if(this.simpleProduct.data.productObject && Object.keys(this.simpleProduct.data.productObject).length > 0 && (
            this.simpleProduct.data.price !== this.simpleProduct.data.productObject.price ||
            this.simpleProduct.data.nameCyrillic !== this.simpleProduct.data.productObject.name_cyrillic ||
            this.simpleProduct.data.hSCode !== this.simpleProduct.data.productObject.hs_code ||
            this.simpleProduct.data.count !== this.simpleProduct.data.productObject.quantity
      )){
        return true
      }
      return false
    },

    getHS() {
      return this.simpleProduct.validation.hSCode
    }
  },

  data() {
    return {
      options: [{}],
      serverError: false,
    }
  },

  mounted() {
    if((this.loadUserAuthorizedData && this.userId > -1) || this.isAdmin) {
      this.getSimpleProduct()
    }
  },

  methods: {
    functionSearchSimpleProduct(val, loading) {
      loading(true)

      if (this.proformSearchTimeoutTime !== undefined) {
        let dateNow = new Date()
        if (dateNow - this.proformSearchTimeoutTime < 500) {
          clearTimeout(this.proformSearchTimeout)
        }
      }

      this.createTimeOut(val, loading)
    },

    createTimeOut(val, loading) {
      this.proformSearchTimeoutTime = new Date()
      this.proformSearchTimeout = setTimeout(() => {
        this.getSimpleProduct(val, loading)
      }, 500)
    },

    getSimpleProduct(val = false, loading){
      let userId
      if (this.userId > -1)
        userId = this.userId
      else
        userId = this.$store.getters.getUserProfile.id


      const query = new this.Query();
      let url = '?';
      let myQuery = query
          .for('posts')

      myQuery.where('SimpleProductUserId', userId)

      if(val) myQuery.where('SimpleProductName', val)

      url = url + myQuery.limit(100000).page(0).url().split('?')[1]

      this.$store.dispatch('fetchSimpleProductProfile', url).then(() => {
        if(this.$store.getters.getSimpleProductProfile.length === 0 && val){
          this.$store.commit('setSimpleProductProfile', [{name: val}])
          this.simpleProduct.setSimpleProductObject({name: val})
        }
        if(loading)
          loading(false)
      }).catch(error => this.createErrorLog(error))

    },

    changeSimpleProduct(val) {
      this.simpleProduct.setItem(val)
    },


    addToProfile() {


      /**
       * I check or when I click to add a profile, the name field is filled.
       * Added to fix empty solect during price or quantity changes
      */
      if(this.simpleProduct.data.name !== '' || this.wasChangedProductData){
        this.simpleProduct.setName(this.simpleProduct.data.productObject.name)
      }


      if(!this.simpleProduct.dataValidation({
        name: true,
        price: true,
        nameCyrillic: this.nameCyrillic,
      }))
        return

      let data = this.simpleProduct.prepareData(this.userId)

      this.createProduct(data).then(() => {
        this.simpleProduct.setName('')
      })
    },

    createProduct(data) {
      return this.$store.dispatch('createSimpleProductProfile', data).then((response) => {
        if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
          this.openNotify('success', 'common_notificationRecordCreated')

        } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {

          let errors = response.response.data.errors;
          this.openNotify('error', {txtServer: errors['name'][0]})

        } else {
          this.openNotify('error', 'common_notificationUndefinedError')
        }
      })
    },

    changeValueSum(val){
      this.simpleProduct.setPrice(val)
    },

    changeValueQuantity(val) {
      this.simpleProduct.setCount(val)
    },
  }

}
</script>

<style scoped>

</style>
