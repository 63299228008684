import {internalOrdersMixin} from "./internalOrdersMixin";
import {radioButtonsFunc} from "../creationPageMixins/radioButtonsFunc";
import {Ukrposhta} from "../../components/modules/UkrposhtaModule/models/Ukrposhta";
import {CDEK_LABEL_FILES_TYPES, USA_COUNTRY_ID} from "../../staticData/staticVariables";
import {UkrposhtaPackageTypes} from "../../staticData/ukrposhtaStatic";
import {setPersonalInfoMixin} from "../commonMixins/setPersonalInfoMixin";



export const ukrposhtaOrdersMixins = {

  mixins: [internalOrdersMixin, radioButtonsFunc, setPersonalInfoMixin],

  methods: {

    changeUser(user) {
      this.UkrP.setUserData(user)

      // this.setPersonalInfo(
      //   user,
      //   'contacts',
      //   this.UkrP.data.PersonalInformation,
      //   this.UkrP.data.DeliveryAddress
      // )
    },

    changePayOrderPopup(val) {
      this.payOrderPopup = val
    },

    nextPageHandler() {
      let valid = true

      switch (this.activeStep) {
        case 1:
          valid = this.UkrP.firstValidation()
          break
        case 2:
          valid = this.UkrP.secondValidation()
          break
        case 3:
          valid = this.UkrP.thirdValidation()
          break
        case 4:
          valid = this.UkrP.fourthValidation(this.packingTypes)
          break
      }

      if (valid)
        if (this.activeStep < this.progressStepsSetting.length) this.activeStep = this.activeStep + 1
    },

    changeRecipientCountry() {
      let countryId = this.UkrP.data.RecipientDeliveryAddress.getCountry().id
      if(countryId === USA_COUNTRY_ID){
        this._.filter(this.packingTypes, 'options').map(item => {
          if(item.options?.use_receipt_notification){
            item.options.use_receipt_notification.active = false
            item.options.use_receipt_notification.visible = false
          }
          if(item.options?.is_bulky){
            item.options.is_bulky.active = false
            item.options.is_bulky.visible = false
          }
          if(item.options?.use_direct_flight){
            item.options.use_direct_flight.active = false
            item.options.use_direct_flight.visible = true
          }
        })
      } else {
        this._.filter(this.packingTypes, 'options').map(item => {
          if(item.options?.use_receipt_notification){
            item.options.use_receipt_notification.visible = true
          }
          if(item.options?.is_bulky){
            item.options.is_bulky.visible = true
          }
          if(item.options?.use_direct_flight){
            item.options.use_direct_flight.visible = false
          }
        })
      }
    },

    changePackagingType(type) {

      let item = this._.find(this.packingTypes, {name: type.name})

      this.skipRadioChecked(this.packingTypes, 'active')

      item.active = true

      if(item.name === UkrposhtaPackageTypes.ems.name){
        this.categoryTypes['sale_of_goods'].active = false
        this.categoryTypes['sale_of_goods'].visible = false
        this.categoryTypes['gift'].active = true
      } else {
        this.categoryTypes['sale_of_goods'].active = true
        this.categoryTypes['sale_of_goods'].visible = true
        this.categoryTypes['gift'].active = false
      }
    },

    changePackingSize({item, radioItem}) {
      let radio = this._.find(item.sizeType, {name: radioItem.name})

      this.skipRadioChecked(item.sizeType, 'active')

      radio.active = true
    },

    changePackingInside({item, radioItem}) {
      let radio = this._.find(item.insideType, {name: radioItem.name})

      this.skipRadioChecked(item.insideType, 'active')

      radio.active = true
    },

    changeCategoryType(type) {
      let item = this._.find(this.categoryTypes, {name: type.name})

      this.skipRadioChecked(this.categoryTypes, 'active')

      item.active = true
    },

    changeCheckItem({item, checkItem}) {
      let itemPackingType = this._.find(this.packingTypes, {name: item.name})

      let itemCheckbox = this._.find(itemPackingType.options, {label: checkItem.label})

      itemCheckbox.active = !itemCheckbox.active
    },

    getPackageData(ukrposhta) {
      let packing = this._.find(this.packingTypes, {active: true})
      let category = this._.find(this.categoryTypes, {active: true})

      let data = {
        'cargo_type': packing.name,
        'category_type': category.name, //SALE_OF_GOODS
        'is_printed_content': false
      }

      Object.keys(this.checkboxItems).map(item => {
        data[item] = this.checkboxItems[item]

        if (this._.has(packing.options, item)) {
          data[item] = packing.options[item].active

          if(item === 'use_declared_price' && packing.options[item].active){
            data['declared_value'] = ukrposhta.data.declaredPrice
          }

        }

      })

      if (packing.size) {
        let sizeType = this._.find(packing.sizeType, {active: true})
        data['label_format'] = sizeType.value
      } else {
        data['label_format'] = null
      }

      if (packing.inside) {
        let insideType = this._.find(packing.insideType, {active: true})
        data['is_printed_content'] = insideType.value
      }

      return data
    },

    setPackageData(data) {

      let packingTypes = this.packingTypes
      let categoryTypes = this.categoryTypes

      let packing = this._.find(packingTypes, {name: data.cargo_type})
      let category = this._.find(categoryTypes, {name: data.category_type})

      this.skipRadioChecked(this.packingTypes, 'active')
      this.skipRadioChecked(this.categoryTypes, 'active')

      if(packing) packing.active = true
      if(category) category.active = true

      Object.keys(this.checkboxItems).map(item => {

        if (this._.has(packing.options, item)) {
          packing.options[item].active = data[item]
        }
      })

      if(data['label_format']){
        let sizeType = this._.find(packing.sizeType, {value: data['label_format']})

        this.skipRadioChecked(packing.sizeType, 'active')

        sizeType.active = true
      }

      if(data['is_printed_content']){
        let insideType = this._.find(packing.insideType, {value: data['is_printed_content'] === 1})

        this.skipRadioChecked(packing.insideType, 'active')

        insideType.active = true
      }


      return packingTypes
    },

    createLabel(id, fromTable = false) {
      this.$store.dispatch('createUkrPoshtaLabel', id).then(response => {
        if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

          this.openNotify('success', 'common_notificationRecordCreated')

          if(fromTable) {
            this.$emit('reload')
            if(this.payOrderPopup) this.payOrderPopup = false
            return
          }

          this.$router.push(this.$store.getters.GET_PATHS.ukrposhta)

        } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
          let errors = response.response.data.errors;

          this.openNotify('error', {txtServer: errors[0]})
        } else {
          this.openNotify('error', 'common_notificationUndefinedError')
        }
      })
    },

    prepareGetDeliveryPrice(ukrposhta) {
      let prepareData = ukrposhta ? ukrposhta.prepareData() : this.UkrP.prepareData()
      let packageData = this.getPackageData(ukrposhta)

      return {
        "directFlight": packageData.use_direct_flight,
        "bulky": packageData.is_bulky,
        "fragile": packageData.is_fragile,
        "withDeliveryNotification": packageData.use_delivery_notification,
        "w2d": packageData.use_hand_over_personally,

        "declaredPrice": packageData.use_declared_price === 1 ? packageData.declared_value : null,

        "packageType": packageData.cargo_type,
        "weight": prepareData.weight,
        "length": prepareData['length'],
        "width": prepareData.width,
        "height": prepareData.height,
        "currencyCode" : prepareData.use_declared_price ? "UAH" : "USD",
        "transportType" : "AVIA",
        "avia" : true,
        "recipientCountryIso3166": prepareData.recipient_country.code_iso_2,
        "recommended": packageData.use_receipt_notification
      }
    },

    getDeliveryPrice() {
      if(this.isAdmin && !this.UkrP.adminValidation(this.packingTypes)) {
        return
      }

      // let data = this.UkrP.prepareGetDeliveryPrice()
      let data = this.prepareGetDeliveryPrice(this.UkrP)

      this.$store.dispatch('getRateUkrPoshta', data).then(response => {
        if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

          this.orderPrice = response.data.data.deliveryPrice + ''
          this.changePayOrderPopup(true)
        } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
          let errors = response.response.data.errors

          if(Array.isArray(errors) && errors.length > 0){
            this.openNotify('error', {txtServer: errors[0]})
            return
          }

          if(Object.keys(errors).length > 0){
            this.openNotify('error', {txtServer: this._.first(errors[this._.first(Object.keys(errors))]) })
          }


        } else {
          this.openNotify('error', 'common_notificationUndefinedError')
        }
      })
    },

    save(draft, edit){

      if(this.isAdmin && !this.UkrP.adminValidation(this.packingTypes)) {
        return
      }

      let prepareData = this.UkrP.prepareData(this.isAdmin)
      let packageData = this.getPackageData(this.UkrP)


      let data = {...prepareData, ...packageData}

      if(edit){
        data = {
          data: data,
          id: this.UkrP.getId()
        }
      }

      if(draft){
        let confirm = () => {
          this.saveOrderInternal(draft, edit, data, 'ukrposhta').then(status => {
            this.successSave(status)
          })

          this.$store.commit('setSaveInDraftsPopup', false)
          return true
        }

        this.saveInDraftsFunc(confirm)
      } else {
        this.saveOrderInternal(draft, edit, data, 'ukrposhta').then(response => {
          this.createLabel(response.id)
          return true
        })
      }
    },

    successSave(status) {
      if(!status) return

      // if(this.isCurrentUserRoleUser){
      //   this.$router.push(this.$store.getters.GET_PATHS.expressOrders)
      //   return
      // }

      this.$router.push(this.$store.getters.GET_PATHS.ukrposhta)
    }

  },

}



export const ukrposhtaOrdersTableMixins = {

  mixins: [ukrposhtaOrdersMixins],

  methods: {
    getDeliveryPriceFromTable(id) {
      let ukrposhta = new Ukrposhta()

      this.$store.dispatch('getOrdersInternal', id).then(() => {

        let order = this.$store.getters.getOrdersInternalItem.orderInternal

        ukrposhta.setUkrposhtaItem(order)
        this.packingTypes = this.setPackageData(order)

        let data = this.prepareGetDeliveryPrice(ukrposhta)


        this.$store.dispatch('getRateUkrPoshta', data).then(response => {
          if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

            this.orderPrice = response.data.data.deliveryPrice + ''
            this.orderId = id
            this.changePayOrderPopup(true)
          } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            let errors = response.response.data.errors;

            this.openNotify('error', {txtServer: errors[0]})
          } else {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        })
      }).catch(error => this.createErrorLog(error))
    },

    // openPoshtaLink(link){
    //   let pdfWindow = window.open("label")
    //   pdfWindow.document.write("<style type='text/css'>body {margin: 0; overflow: hidden}</style> <iframe width='100%' height='100%' src='"+ link +"'></iframe>")
    // },

    getUkrposhtaLabelUUID(filesArr){
      return this._.find(filesArr, {type: CDEK_LABEL_FILES_TYPES.labelFile})?.uuid
    },

    getUkrposhtaF103AUUID(filesArr){
      return this._.find(filesArr, {type: CDEK_LABEL_FILES_TYPES.form103AFile})?.uuid
    },

  }
}
