<template>
  <div class="order-create__section">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta_ProductInformation'])"></div>
      {{$t('poshta_ProductInformation.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--50 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_ProductInformation', 'poshta_OneShipment'])"></div>
        <WarningStatic class="mw-100 w-100">
          <template slot="title">
            {{$t('poshta_ProductInformation.localization_value.value')}}
          </template>
          <template slot="text">
            {{$t('poshta_OneShipment.localization_value.value')}}
          </template>
        </WarningStatic>
      </div>
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col mb-0">
        <UkrposhtaProduct
          v-for="(item, index) in UkrP.data.UkrposhtaProduct"
          :key="index"
          :ukrposhtaProduct="item"
          :UkrP="UkrP"
          :index="index + 1"
          @removeProduct="UkrP.removeUkrposhtaProduct(index)"
        />
        <div class="order-create__product custom-row"
             v-if="UkrP.data.UkrposhtaProduct.length < 4"
        >
          <div class="order-create__product-col custom-col custom-col--md-100">
            <div class="order-create__product-btn add-product-btn-wrap">
              <div class="order-create__product-btn-i add-product-btn site-link"
                    @click="UkrP.addUkrposhtaProduct()"
                    v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_AddProduct'])"></div>
              +  {{$t('common_AddProduct.localization_value.value')}}
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['packaging_Packaging', ])"></div>
      {{$t('packaging_Packaging.localization_value.value')}}
    </div>

    <PackagingBlock
            :userId="UkrP.data.User.userId"
            :packaging="UkrP.data.Packaging"
    />




    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta_shippingInformation'])"></div>
      {{$t('poshta_shippingInformation.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--xs-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_ShippingDate'])"></div>
        <DatePickerDefault
                :label="$t('poshta_ShippingDate.localization_value.value')"
                v-bind:class="{'ui-no-valid': UkrP.validation.shippingDate}"
                :errorTxt="$t(`${UkrP.validationTranslate.shippingDate}.localization_value.value`)"
                :error="UkrP.validation.shippingDate"
                :value="UkrP.data.shippingDate"
        >
          <template slot="body">
            <date-picker
                    v-model="UkrP.data.shippingDate"
                    valueType="format"
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>
    </div>
    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col mb-0"
           v-if="UkrP.data.isVatNumberByCountry"
      >
        <div class="order-create__section-label section-label"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
                          'express_Other',
                        ])"></div>
          {{$t('express_Other.localization_value.value')}}
        </div>

        <div class="order-create__product custom-row mt-3">

          <div class="order-create__product-col custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                  'common_vatNumber',
                ])"></div>

            <DefaultCheckbox
                    v-model="UkrP.data.visibleVatNumber"
                    :label="$t('common_vatNumber.localization_value.value')"
                    @click="UkrP.data.visibleVatNumber = !UkrP.data.visibleVatNumber"
            />
          </div>
          <div class="order-create__product-col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
               v-if="UkrP.data.visibleVatNumber"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                  'common_vatNumber',
                ])"></div>

            <DefaultInput
                    :label="$t('common_vatNumber.localization_value.value')"
                    v-bind:class="{'ui-no-valid': UkrP.validation.vatNumber}"
                    :error="UkrP.validation.vatNumber"
                    :errorTxt="$t(`${UkrP.validationTranslate.vatNumber}.localization_value.value`)"
                    :type="'text'"
                    v-model="UkrP.data.vatNumber"
            />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  // import DeleteProductBtn from "../../../../../../../coreComponents/DeleteProductBtn/DeleteProductBtn";
  // import DefaultSelect from "../../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  // import InputQuantity from "../../../../../../../UI/inputs/InputQuantity/InputQuantity";
  import {mixinDetictingMobile} from "../../../../../../mixins/mobileFunctions";
  import WarningStatic from "../../../../../UI/alerts/WarningStatic/WarningStatic";
  import DatePickerDefault from "../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import UkrposhtaProduct from "./UkrposhtaProduct/UkrposhtaProduct";
  import PackagingBlock from "../../../../../coreComponents/PackagingBlock/PackagingBlock";
  import DefaultCheckbox from "../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
  // import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";

  export default {
    name: "StepThree",
    components:{
      DefaultInput,
      DefaultCheckbox,
      PackagingBlock,
      // DefaultInput,
      UkrposhtaProduct,
      // DeleteProductBtn,
      // DefaultSelect,
      // InputQuantity,
      WarningStatic,
      DatePickerDefault,
      DatePicker,
    },

    mixins: [mixinDetictingMobile],

    props: {
      UkrP: Object,
    },

    data(){
      return{
        options: [{}],

        name: '',
        valueQuantity: 1,
        valueSum: 100,

        isModalSetPopup: false,
      }
    },

    methods: {
      changeSetPopup(val){
        this.isModalSetPopup = val
      }
    },
  }
</script>

<style scoped>

</style>
