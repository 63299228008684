import { render, staticRenderFns } from "./StepFour.vue?vue&type=template&id=53ad8c17&scoped=true"
import script from "./StepFour.vue?vue&type=script&lang=js"
export * from "./StepFour.vue?vue&type=script&lang=js"
import style0 from "./StepFour.vue?vue&type=style&index=0&id=53ad8c17&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53ad8c17",
  null
  
)

export default component.exports