<template>
  <div class="detail-page">
    <div class="detail-page__left">
      <CardLeftBlock
              :name="$t('poshta_number.localization_value.value')"
              :value="'#' + UkrP.data.id"
              :backgroundImage="'orders'"
      >
        <template slot="body">
          <div class="d-flex mt-3" v-if="UkrP.data.trackingNumber !== ''">
            <div class="card-detail-left__name">
              {{ $t('poshta_TrackNumber.localization_value.value') }}
            </div>
            <div class="card-detail-left__date ml-2">{{UkrP.data.trackingNumber}}</div>
          </div>

          <div class="d-flex mt-3" v-if="UkrP.data.transactionNumber !== ''">
            <div class="card-detail-left__name">
              {{ $t('poshta_PayPalTransaction.localization_value.value') }}
            </div>
            <div class="card-detail-left__date ml-2">{{UkrP.data.transactionNumber}}</div>
          </div>

        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col">
                <router-link :to="$store.getters.GET_PATHS.ukrposhta" class="order-create__head-back">
                  <LinkBack
                          :value="$t('ex_backTo.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>

            <div class="order-create__head-title">
              Order Ukrposhta
            </div>
          </div>
        </template>

        <template slot="body">
          <StepFive
                  :UkrP="UkrP"
                  :PersonalInformation="UkrP.data.PersonalInformation"
                  :DeliveryAddress="UkrP.data.DeliveryAddress"
                  :packingTypes="packingTypes"
                  :categoryTypes="categoryTypes"
                  :showEdit="false"
          />
        </template>
        <template slot="footer">
          <div class="order-create__footer">
            <div class="order-create__footer-link"
                 v-if="UkrP.data.status !== INTERNAL_STATUSES.COMPLETE.id"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate(['common_deleteOrder '])"></div>

              <span class="site-link site-link--alt"
                    @click="removeOrderInternal(UkrP.data.id, INTERNAL_DELIVERY_SERVICES.UKRPOSHTA_DELIVERY_SERVICE.id)"
              >
                {{ $t('common_deleteOrder.localization_value.value') }}
              </span>
            </div>


            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate(['common_createACopy'])"></div>
              <MainButton
                      class="order-create__footer-btn-i wfc"
                      @click.native="copy"
                      :value="$t('common_createACopy.localization_value.value')"
              />
            </div>

          </div>
        </template>
      </CardRightBlock>

    </div>

  </div>
</template>

<script>
  import CardLeftBlock from "../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import CardRightBlock from "../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../UI/buttons/LinkBack/LinkBack";
  // import TitleHorizontal from "../../../../coreComponents/TitleHorizontal/TitleHorizontal";
  // import RadioDefault from "../../../../UI/radiobuttons/RadioDefault/RadioDefault";
  // import TooltipBtn from "../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  // import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  // import ArrowRightBrownIco from '../../../../../../public/img/UI-group/arrow-right-brown.svg?inline';
  // import DefaultCheckbox from "../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import {mixinDetictingMobile} from "../../../../../mixins/mobileFunctions";
  import StepFive from "../components/StepFive/StepFive";
  import {ukrposhtaOrdersMixins} from "../../../../../mixins/internalOrdersMixins/ukrposhtaOrdersMixin";
  import {
    UkrposhtaCategoryTypes,
    UkrposhtaCheckboxItems,
    UkrposhtaPackageTypes
  } from "../../../../../staticData/ukrposhtaStatic";
  import {Ukrposhta} from "../../models/Ukrposhta";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import {INTERNAL_DELIVERY_SERVICES, INTERNAL_STATUSES} from "../../../../../staticData/staticVariables";

  export default {
    name: "UkrposhtaShow",

    mixins: [mixinDetictingMobile, ukrposhtaOrdersMixins],

    components: {
      MainButton,
      StepFive,
      CardLeftBlock,
      CardRightBlock,
      LinkBack,
      // TitleHorizontal,
      // RadioDefault,
      // TooltipBtn,
      // ArrowRightBrownIco,
      // MainButton,
      // DefaultCheckbox,
    },

    data() {
      return {
        UkrP: new Ukrposhta(),

        packingTypes: JSON.parse(JSON.stringify(UkrposhtaPackageTypes)),

        checkboxItems: UkrposhtaCheckboxItems,

        categoryTypes: UkrposhtaCategoryTypes,

        payOrderPopup: false,

        INTERNAL_STATUSES: INTERNAL_STATUSES,
        INTERNAL_DELIVERY_SERVICES: INTERNAL_DELIVERY_SERVICES,

        orderPrice: -1,
      }
    },

    mounted() {
      this.UkrP.setId(this.$route.params.id)

      this.$store.dispatch('getOrdersInternal', this.UkrP.getId()).then(() => {
        let order = this.$store.getters.getOrdersInternalItem.orderInternal
        this.UkrP.setUkrposhtaItem(order)

        this.packingTypes = this.setPackageData(order)
      }).catch(error => this.createErrorLog(error))

    },

    methods: {
      copy() {
        this.copyOrder(this.UkrP.getId()).then(() => {
          this.$router.push(this.$store.getters.GET_PATHS.ukrposhta)
        })
      }
    }

  }
</script>

<style scoped lang="scss">
  @import "../../../../../scss/mixins/mixins";

  @include for-680 {
    .order-create__footer-link {
      width: 100%;
    }
  }

</style>
