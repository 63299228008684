import {checkValid} from "./functions/ModelsValidate";
// let _ = require('lodash');

export function SimpleProduct() {

  this.checkValid = checkValid

  this.data = {

    /***
     * Pseudo Required Fields start
     */

    user: {},
    userId: '',
    id: '',

    /*** ***/

    productObject: null,
    name: '',
    nameCyrillic: '',
    count: 1,
    price: 0,
    hSCode: '',
    totalSum: 0,

  }

  this.validation = {
    user: false,
    name: false,
    nameCyrillic: false,
    productObject: false,
    count: false,
    price: false,
    hSCode: false,
  }

  this.validationTranslate = {
    user: '',
    name: '',
    nameCyrillic: '',
    productObject: '',
    count: '',
    price: '',
    hSCode: '',
  }

  this.validationTxt = {
    user: false,
    name: false,
    nameCyrillic: false,
    productObject: false,
    count: false,
    price: false,
    hSCode: false,
  }

}


/*****************   SETTERS   ******************/

/***
 * Pseudo Required Fields start
 */

SimpleProduct.prototype.setUser = function (val) {
  this.data.user = val
  this.setUserId(val.id)
}

SimpleProduct.prototype.setUserId = function (val) {
  this.data.userId = val
}

SimpleProduct.prototype.setId = function (val) {
  this.data.id = val
}

/*** **/

SimpleProduct.prototype.setProduct = function(val){
  this.data.productObject = val
}

SimpleProduct.prototype.setName = function(val){
  this.data.name = val
}

SimpleProduct.prototype.setNameCyrillic = function(val){
  this.data.nameCyrillic = val
}

SimpleProduct.prototype.setCount = function(val){
  this.data.count = val
  this.totalSumComputation()
}

SimpleProduct.prototype.setPrice = function(val){
  this.data.price = val
  this.totalSumComputation()
}

SimpleProduct.prototype.setTotalSum = function(val){
  this.data.totalSum = val
}

SimpleProduct.prototype.setHSCode = function(val){
  this.data.hSCode = val
}

SimpleProduct.prototype.setSimpleProductObject = function(val, isNotSetName = false){
  this.data.productObject = val

  if(isNotSetName) {
    this.setName('')
    return
  }

  this.setName(val.name ? val.name : '')
}

SimpleProduct.prototype.setItem = function(val){
  this.setSimpleProductObject(val, true)
  this.setNameCyrillic(val.name_cyrillic ? val.name_cyrillic : '')
  this.setPrice(val.price ? val.price : 0)
  this.setCount(val.quantity ? val.quantity : 1)
  this.setHSCode(val["hs_code"] ? val["hs_code"] : '')
}

SimpleProduct.prototype.setItemForEdit = function(val){
  this.setSimpleProductObject(val)
  this.setName(val.name ? val.name : '')
  this.setNameCyrillic(val.name_cyrillic ? val.name_cyrillic : '')
  this.setPrice(val.price ? val.price : 0)
  this.setCount(val.quantity ? val.quantity : 1)
  this.setHSCode(val["hs_code"] ? val["hs_code"] : '')
}



/*****************   GETTERS   ******************/

/***
 * Pseudo Required Fields start
 */

SimpleProduct.prototype.getUser = function () {
  return this.data.user
}

SimpleProduct.prototype.getUserId = function () {
  return this.data.userId
}

SimpleProduct.prototype.getId = function () {
  return this.data.id
}

SimpleProduct.prototype.getType = function () {
  return this.data.type
}

SimpleProduct.prototype.getSourceOrderId = function () {
  return this.data.sourceOrderId
}

/*** **/

SimpleProduct.prototype.get = function(){
  return this.data.productObject
}

SimpleProduct.prototype.getName = function(){
  return this.data.name
}

SimpleProduct.prototype.getNameCyrillic = function(){
  return this.data.nameCyrillic
}

SimpleProduct.prototype.getCount = function(){
  return this.data.count
}

SimpleProduct.prototype.getPrice = function(){
  return this.data.price
}


SimpleProduct.prototype.getTotalSum = function(){
  return this.data.totalSum
}

SimpleProduct.prototype.getHSCode= function(){
  return this.data.hSCode
}

/*******************   FUNCTIONS   *************/

SimpleProduct.prototype.totalSumComputation = function() {
  let quantity = this.data.count,
      price = this.data.price
  this.setTotalSum(parseFloat(quantity * price).toFixed(2))
}

/******************  VALIDATIONS  *****************/


SimpleProduct.prototype.dataValidation = function (
  {
    user,
    productObject,
    name,
    nameCyrillic,
    count,
    price,
    hSCode,
    hsCodeLength,
    nameCyrillicAndLatin,
  }) {

  let validationItems = {
    user: this.data.userId,
    productObject: this.data.productObject?.name ? this.data.productObject.name : '',
    name: this.data.name,
    nameCyrillic: this.data.nameCyrillic,
    count: this.data.count,
    price: this.data.price,
    hSCode: this.data.hSCode,
  }

  let validationOptions = {
    user: {type: user ? ['empty'] : []},
    productObject: {type: productObject ? ['empty'] : []},
    name: {type: name ? ['empty', nameCyrillicAndLatin ? 'empty' : 'latin'] : []},
    nameCyrillic: {type: nameCyrillic ? ['empty', 'onlyCyrillic'] : []},
    count: {type: count ? ['empty', 'numeric', 'not-zero'] : []},
    price: {type: price ? ['empty', 'numeric', 'not-zero'] : []},
    hSCode: {type: hSCode ? ['empty', 'numeric'] : []},
  }

  if(hSCode && this.data.hSCode.length > 0 && hsCodeLength && (this.data.hSCode.length > hsCodeLength.max || this.data.hSCode.length < hsCodeLength.min)) {
    this.validation.hSCode = true
    this.validationTranslate.hSCode = 'error_ukrposhtaHSValid'
    return false
  }

  return (this.checkValid(validationItems, validationOptions))
}



/******************  PREPARE DATA  *****************/


SimpleProduct.prototype.prepareData = function(userId = false) {

  return {
    "user_id" : userId ? userId : this.getUserId(),
    "name": this.getName(),
    "name_cyrillic": this.getNameCyrillic() ? this.getNameCyrillic() : null,
    "price": this.getPrice(),
    "quantity": this.getCount(),
    "hs_code": this.getHSCode() ? this.getHSCode() : null
  }
}

// SimpleProduct.prototype.prepareNewSelectedData = function(userId) {
//
//   return {
//     "user_id" : userId,
//     "name": this.getName(),
//     "name_cyrillic": this.getNameCyrillic() ? this.getNameCyrillic() : null,
//     "price": this.getPrice(),
//     "quantity": this.getCount(),
//     "hs_code": this.getHSCode() ? this.getHSCode() : null
//   }
// }
