<template>
  <div class="fragment">


    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--separator">

      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block">
        <DefaultCheckbox
                :label="$t('warehouseManagement_selectAll.localization_value.value')"
                @input="(val) => $emit('selectAll', val)"
        />
      </div>

      <div class="btn-left-block">
        <div class="btn-left-block__list">
          <div class="btn-left-block__list__i">
            <TopTableBoxesBtn
                    class="btn-left-block-i--link"
                    :type="'boxes'"
                    :title="'poshta_createGroup'"
                    @click.native="$emit('createGroupF103A')"
            />
          </div>
        </div>
      </div>


      <div class="btn-right-block">
        <router-link :to="$store.getters.GET_PATHS.ukrposhtaCreation"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
                'poshta_addNew',
              ])"></div>
          <MainButton class="btn-fit-content ml-3"
                      :value="$t('poshta_addNew.localization_value.value')"
                      :ico="'ico'"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus"/>
            </template>
          </MainButton>
        </router-link>
      </div>
    </div>



  </div>
</template>



<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import TopTableBoxesBtn from "../../../../../../coreComponents/Buttons/TopTableBoxesBtn/TopTableBoxesBtn";

  export default {
    name: "UkrposhtaHead",
    components: {
      TopTableBoxesBtn,
      DefaultCheckbox,
      ToggleFilterButton,
      MainButton,
      PlusIconSVG,
      // DefaultCheckbox,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
    },


    data(){
      return{

      }
    },

    methods: {

    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/mixins/mixins";

  .light-bg-block{
    display: flex;
    flex-wrap: wrap;
  }

  @include for-992{
    /*.btn-left-block {*/
      /*margin-bottom: 15px;*/
    /*}*/

    .btn-right-block{
      flex-wrap: wrap;
    }

    /*.main-button{*/
      /*margin-bottom: 10px;*/
    /*}*/

  }

  .btn-left-block-i--link{
    position: relative;
    top: 2px;
    margin-left: 10px;
  }



</style>

