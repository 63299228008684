<template>
  <div class="fragment">
    <UkrposhtaEditingPage
        v-if="loadedOrder"
        :UkrP="UkrP"
        :packingTypes="packingTypes"
        :categoryTypes="categoryTypes"
        :selectedCountry="UkrP.data.RecipientDeliveryAddress.delivery.country"
        @changeRecipientCountry="changeRecipientCountry"
        @changePackagingType="changePackagingType"
        @changePackingSize="changePackingSize"
        @changePackingInside="changePackingInside"
        @changeCategoryType="changeCategoryType"
        @changeCheckItem="changeCheckItem"
        @edit="save(true, true)"
        @getDeliveryPrice="getDeliveryPrice"
    />

    <PayOrderPopup
            v-if="payOrderPopup"
            :orderPrice="orderPrice"
            :disableBtn="$store.getters.getNovaPoshtaBtn || $store.getters.getUkrPoshtaBtn"
            :currency="'UAH'"
            :generateDocsBtn="true"
            @close="changePayOrderPopup(false)"
            @createLabel="save(false, true)"
    />
  </div>
</template>

<script>

  import {Ukrposhta} from "../../models/Ukrposhta";
  import UkrposhtaEditingPage from "./UkrposhtaEditingPage/UkrposhtaEditingPage";
  import PayOrderPopup from "../../../../coreComponents/Popups/PayOrderPopup/PayOrderPopup";
  import {ukrposhtaOrdersMixins} from "../../../../../mixins/internalOrdersMixins/ukrposhtaOrdersMixin";
  import {
    UkrposhtaCategoryTypes,
    UkrposhtaCheckboxItems,
    UkrposhtaPackageTypes
  } from "../../../../../staticData/ukrposhtaStatic";

  export default {
    name: "UkrposhtaEditing",

    components: {
      UkrposhtaEditingPage,
      PayOrderPopup,
    },

    mixins: [ukrposhtaOrdersMixins],

    watch: {
      loadUserRoleAndAuthorizedData: function () {
        this.checkUserRightsToUkrposhta()
      },
    },

    data(){
      return{
        UkrP: new Ukrposhta(),

        packingTypes: JSON.parse(JSON.stringify(UkrposhtaPackageTypes)),

        checkboxItems: UkrposhtaCheckboxItems,

        categoryTypes: UkrposhtaCategoryTypes,

        payOrderPopup: false,

        loadedOrder: false,

        orderPrice: -1,
      }
    },

    mounted() {
      if (this.loadUserRoleAndAuthorizedData) {
        this.checkUserRightsToUkrposhta()
      }

      this.UkrP.setId(this.$route.params.id)

      this.$store.dispatch('getOrdersInternalEdit', this.UkrP.getId()).then(() => {
        let order = this.$store.getters.getOrdersInternalItem.orderInternal
        this.UkrP.setUkrposhtaItem(order)

        this.loadedOrder = true

        this.packingTypes = this.setPackageData(order)
      }).catch(error => this.createErrorLog(error))

    },

    methods: {
      checkUserRightsToUkrposhta(){
        if(this.isCurrentUserRoleUser && !this.isUkrainian){
          this.$router.push(this.$store.getters.GET_PATHS.notFound)
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  @import "../../../../../scss/mixins/mixins";

  @include for-680{
    .order-create__footer-link{
      width: 100%;
    }
  }

</style>
