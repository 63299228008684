
export const UkrposhtaPackageTypes = {
  "parcel": {
    active: true,
    name: "PARCEL",
    label: "poshta_packageParcel",
    tooltip: {
      name: "poshta_packageParcelTooltip",
      description: "poshta_packageParcelTooltipDesc",
    },
    options: {
      is_bulky: {
        active: false,
        label: "poshta_packageIsBulky",
        visible: true,
      },
      use_declared_price: {
        active: false,
        label: "poshta_packageUseDeclaredPrice",
        visible: true,
        inputShow: true,
      },
      use_receipt_notification: {
        active: false,
        label: "poshta_packageUseReceiptNotification",
        visible: true,
      },
    },
    size: false,
    inside: false,
  },
  "small_bag": {
    active: false,
    name: "SMALL_BAG",
    label: "poshta_packageSmallBag",
    tooltip: {
      name: "poshta_packageSmallBagTooltip",
      description: "poshta_packageSmallBagTooltipDesc",
    },
    options: {
      use_hand_over_personally: {
        active: false,
        label: "poshta_packageUseHandOverPersonally",
        visible: true,
      },
      use_delivery_notification: {
        active: false,
        label: "poshta_packageUseDeliveryNotification",
        visible: true,
      },
      use_receipt_notification: {
        active: false,
        label: "poshta_packageUseReceiptNotification",
        visible: true,
      },
      use_direct_flight: {
        active: false,
        label: "poshta_useDirectFlight",
        visible: true,
      },
    },
    size: true,
    sizeType: [
      {
        active: true,
        name: "poshta_A5",
        value: "SIZE_A5",
      },
      {
        active: false,
        name: "poshta_A6",
        value: "SIZE_A6",
      },
      {
        active: false,
        name: "poshta_10x10",
        value: "SIZE_10X10",
      },
    ],
    inside: false,
  },

  "letter": {
    active: false,
    name: "LETTER",
    label: "poshta_packageLetter",
    tooltip: {
      name: "poshta_packageLetterTooltip",
      description: "poshta_packageLetterTooltipDesc",
    },
    options: {
      use_receipt_notification: {
        active: false,
        label: "poshta_packageUseReceiptNotification",
        visible: true,
      },
    },
    size: true,
    sizeType: [
      {
        active: true,
        name: "poshta_None",
        value: null,
      },
      {
        active: false,
        name: "poshta_A4",
        value: "SIZE_A4",
      },
      {
        active: false,
        name: "poshta_A5",
        value: "SIZE_A5",
      },
    ]
  },

  "banderole": {
    active: false,
    name: "BANDEROLE",
    label: "poshta_packageBanderole",
    tooltip: {
      name: "poshta_packageBanderoleTooltip",
      description: "poshta_packageBanderoleTooltipDesc",
    },
    options: {
      use_receipt_notification: {
        active: false,
        label: "poshta_packageUseReceiptNotification",
        visible: true,
      },
    },
    size: false,
    inside: true,
    insideKey: "is_printed_content",
    insideType: [
      {
        active: true,
        name: "poshta_packageProduct",
        value: false
      },
      {
        active: false,
        name: "poshta_packagePrinted",
        value: true
      },

    ]
  },

  "ems": {
    active: false,
    name: "EMS",
    label: "poshta_packageEMS",
    tooltip: {
      name: "poshta_packageEMSTooltip",
      description: "poshta_packageEMSTooltipDesc",
    },
    options: {
      use_receipt_notification: {
        active: false,
        label: "poshta_packageUseReceiptNotification",
        visible: true,
      },
    },
    size: false,
    inside: false,
    // insideKey: "is_printed_content",
    // insideType: [
    //   {
    //     active: true,
    //     name: "poshta_packageProduct",
    //     value: false
    //   },
    //   {
    //     active: false,
    //     name: "poshta_packagePrinted",
    //     value: true
    //   },
    //
    // ]
  },
}


export const UkrposhtaCheckboxItems = {
  "is_bulky": false,
  "is_fragile": false,
  "use_declared_price": false,
  "use_receipt_notification": false,
  "use_hand_over_personally": false,
  "use_delivery_notification": false,
  "use_direct_flight": false,
}

export const UkrposhtaCategoryTypes = {
  'sale_of_goods': {
    active: true,
    visible: true,
    name: 'SALE_OF_GOODS',
    label: 'poshta_packageSALEOFGOODS',
  },
  'gift': {
    active: false,
    visible: true,
    name: 'GIFT',
    label: 'poshta_packageGIFT',
  },

  // 'commercial_sample': 'COMMERCIAL_SAMPLE',
  // 'documents': 'DOCUMENTS',
  // 'returning_goods': 'RETURNING_GOODS',
  // 'mixed_content': 'MIXED_CONTENT',
}
