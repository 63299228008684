<template>
  <div class="fragment">
    <UkrposhtaCreationSection
      :UkrP="UkrP"
      :selectedCountry="UkrP.data.RecipientDeliveryAddress.delivery.country"
      :packingTypes="packingTypes"
      :categoryTypes="categoryTypes"
      @changeRecipientCountry="changeRecipientCountry"
      @changePackagingType="changePackagingType"
      @changePackingSize="changePackingSize"
      @changePackingInside="changePackingInside"
      @changeCategoryType="changeCategoryType"
      @changeCheckItem="changeCheckItem"
      @getDeliveryPrice="getDeliveryPrice"
      @create="save"
    />


    <PayOrderPopup
            v-if="payOrderPopup"
            :orderPrice="orderPrice"
            :disableBtn="$store.getters.getNovaPoshtaBtn || $store.getters.getUkrPoshtaBtn"
            :currency="'UAH'"
            :generateDocsBtn="true"
            @close="changePayOrderPopup(false)"
            @createLabel="save"
    />
  </div>
</template>

<script>
  import UkrposhtaCreationSection from "./UkrposhtaCreationSection/UkrposhtaCreationSection";
  import {Ukrposhta} from "../../models/Ukrposhta";
  import {
    UkrposhtaCategoryTypes,
    UkrposhtaCheckboxItems,
    UkrposhtaPackageTypes
  } from "../../../../../staticData/ukrposhtaStatic";
  import {radioButtonsFunc} from "../../../../../mixins/creationPageMixins/radioButtonsFunc";
  import {ukrposhtaOrdersMixins} from "../../../../../mixins/internalOrdersMixins/ukrposhtaOrdersMixin";
  import PayOrderPopup from "../../../../coreComponents/Popups/PayOrderPopup/PayOrderPopup";

  export default {
    name: "UkrposhtaCreation",
    components: {
      PayOrderPopup,
      UkrposhtaCreationSection,
    },

    mixins: [radioButtonsFunc, ukrposhtaOrdersMixins],

    watch: {
      loadUserRoleAndAuthorizedData: function (val) {
        if(val){
          this.getUserData()
        }
      },
    },

    data() {
      return {
        UkrP: new Ukrposhta(),

        packingTypes: JSON.parse(JSON.stringify(UkrposhtaPackageTypes)),

        checkboxItems: UkrposhtaCheckboxItems,

        categoryTypes: UkrposhtaCategoryTypes,

        payOrderPopup: false,

        orderPrice: -1,
      }
    },

    created() {

    },

    mounted() {
      if (this.loadUserRoleAndAuthorizedData) {
        this.getUserData()
      }

      let transactionId = this.$route.params.id
      let transactionType = this.$route.params.typeTransaction

      if(transactionId && transactionType){
        this.getTransaction(transactionId, transactionType)
      }
    },

    methods: {



      getTransaction(transactionId, transactionType) {
        if(transactionType === this.UkrP.data.typesTransaction.payment) {

          this.$store.dispatch('getCusPayments', transactionId).then(() => {
            let item = this.$store.getters.getCusPaymentsItem
            let paymentDetail = item.payment_transaction_detail
            let paymentDetailContacts = paymentDetail.payment_transaction_contact

            this.UkrP.setRecipientInformation({
              full_name: paymentDetailContacts.full_name,
              phone: paymentDetailContacts.phone,
              email: paymentDetailContacts.email,
            })
            this.UkrP.setRecipientDeliveryAddress({
              address: paymentDetailContacts.address,
              city: paymentDetailContacts.city,
              region: paymentDetailContacts.state,
              country:  {
                id: paymentDetailContacts.countryInstance.id,
                label: paymentDetailContacts.countryInstance.name,
                code_iso_2: paymentDetailContacts.countryInstance.code_iso_2
              },
              zip: paymentDetailContacts.zip_code,
            })

            setTimeout(() => {
              if(this.isAdmin)
                this.changeUser(item.user, 'user_personal_contact')
            }, 300)
          }).catch(error => this.createErrorLog(error))
        }

        if(transactionType === this.UkrP.data.typesTransaction.order) {

          this.$store.dispatch('getImportOrders', transactionId).then(() => {
            let item = this.$store.getters.getImportOrdersItem

            this.UkrP.setRecipientInformation({
              full_name: item.customer_name,
              phone: item.original_phone,
              email: item.original_email
            })
            this.UkrP.setRecipientDeliveryAddress({
              address: item.original_address_line_1,
              address2: item.original_address_line_2,
              city: item.original_city,
              region: item.original_state,
              country: item.country ? {
                id: item.country.id,
                label: item.country.name,
                code_iso_2: item.country.code_iso_2
              } : null,
              zip: item.original_post_code,
            })

            setTimeout(() => {
              if(this.isAdmin)
                this.changeUser(item.user, 'user_personal_contact')
            }, 300)

          }).catch(error => this.createErrorLog(error))
        }

        this.UkrP.setPaymentTransactionId(transactionId)
        this.UkrP.setTypeTransaction(transactionType)

      },

      getUserData() {
        if(this.isCurrentUserRoleUser){

          if(!this.isUkrainian){
            this.$router.push(this.$store.getters.GET_PATHS.notFound)
          }

          let userData = this.$store.getters.GET_COMMON_AUTHORIZED.user
          this.UkrP.setUserData(userData)
        }
      },




    },
  }
</script>

<style scoped>

</style>
