import {AUSTRALIA_ID, UNITED_KINGDOM_ID} from "../../staticData/staticVariables";

export const orderCurrencyMixin = {
  methods:{
    getOrderCurrencyByCountry(countries) {
      let dollar = 'dollar',
        euro = 'euro'

      if(countries?.country_groups.length === 0) return dollar

      let fromHsCodeGroup = this._.find(countries.country_groups, 'fromHsCodeGroup')

      if(!fromHsCodeGroup) return dollar

      if(countries.id === UNITED_KINGDOM_ID) return dollar
      if(countries.id === AUSTRALIA_ID) return dollar

      if(fromHsCodeGroup){
        return euro
      }

      return dollar
    },
  },

}
