<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta_Shipment', 'poshta_TrackNumber'])"></div>
      <CardLeftBlock
          :name="$t('poshta_number.localization_value.value')"
          :value="'#' + UkrP.data.id"
          :backgroundImage="'orders'"
      >
        <template slot="body">
          <!--<div class="d-flex mt-3" v-if="UkrP.data.trackingNumber !== ''">-->
            <!--<div class="card-detail-left__name">-->
              <!--{{ $t('poshta_TrackNumber.localization_value.value') }}-->
            <!--</div>-->
            <!--<div class="card-detail-left__date ml-2">#{{UkrP.data.trackingNumber}}</div>-->
          <!--</div>-->
          <div class="card-detail-left__date"
               v-if="_.has($store.getters.getOrdersInternalItem, 'orderInternal')"
          >
            {{ $store.getters.getOrdersInternalItem.orderInternal.created_at | moment("DD MMM, YYYY") }}
          </div>

        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['poshta_backTo',])"></div>
                <router-link :to="$store.getters.GET_PATHS.ukrposhta" class="order-create__head-back">
                  <LinkBack
                      :value="$t('poshta_backTo.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['poshta_editUkrPoshta'])"></div>
              {{ $t('poshta_editUkrPoshta.localization_value.value') }}
            </div>
            <div class="order-create__head-txt"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['poshta_PleaseInformation'])"></div>
              {{ $t('poshta_PleaseInformation.localization_value.value') }}
            </div>
            <div
                v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                'poshta_sendingDetails',
                'poshta_shippingDetails',
                'poshta_recipient',
                'poshta_confirmation',
              ])"></div>

              <div
                      v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                'poshta_sendingDetails',
                'poshta_recipient',
                'poshta_shippingDetails',
                'poshta_packagingShipping',
                'poshta_confirmation',
              ])"></div>
                <div class="order-create__head-steps"
                     v-if="isCurrentUserRoleUser">
                  <ProgressSteps
                          :options="progressStepsSetting"
                          :step="activeStep"
                          @changeStep="stepChange"
                          @nextPageHandler="nextPageHandler"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
        <template slot="body">

          <div class="order-create__section">
            <div class="custom-row" v-if="$store.getters.getIsAdminRights === 'admin'">
              <div class="custom-col custom-col--33 custom-col--md-100">
                <UserSelect
                    :validationUser="UkrP.validation.user"
                    :validationTxtUser="UkrP.validationTxt.user"
                    :validationTranslateUser="UkrP.validationTranslate.user"
                    :userSelected="UkrP.data.User.user"
                    :typeSelect="'users'"
                    :userEmail="true"
                    @changeUser="changeUser"
                />
              </div>
            </div>
          </div>

          <StepOne v-if="activeStep === 1 || isAdmin"
                   :UkrP="UkrP"
                   :PersonalInformation="UkrP.data.PersonalInformation"
                   :DeliveryAddress="UkrP.data.DeliveryAddress"

          />
          <StepTwo v-if="activeStep === 2 || isAdmin"
                   :UkrP="UkrP"
                   :recipientInformation="UkrP.data.RecipientInformation"
                   :recipientDeliveryAddress="UkrP.data.RecipientDeliveryAddress"
          />
          <StepThree v-if="activeStep === 3 || isAdmin"
                     :UkrP="UkrP"
          />
          <StepFour v-if="activeStep === 4 || isAdmin"
                    :UkrP="UkrP"
                    :packingTypes="packingTypes"
                    :categoryTypes="categoryTypes"
                    @changePackagingType="(item) => $emit('changePackagingType', item)"
                    @changePackingSize="({item, radioItem}) => $emit('changePackingSize', {item, radioItem})"
                    @changePackingInside="({item, radioItem}) => $emit('changePackingInside', {item, radioItem})"
                    @changeCheckItem="({item, checkItem}) => $emit('changeCheckItem', {item, checkItem})"
                    @changeCategoryType="(item) => $emit('changeCategoryType', item)"
          />
          <StepFive v-if="activeStep === 5"
                    :UkrP="UkrP"
                    :PersonalInformation="UkrP.data.PersonalInformation"
                    :DeliveryAddress="UkrP.data.DeliveryAddress"
                    :packingTypes="packingTypes"
                    :categoryTypes="categoryTypes"
                    @changeStep="stepChange"
          />

          <template  v-if="isAdmin">
            <div class="order-create__product-btn add-product-btn-wrap mb-2 mt-3"></div>

            <div class="order-create__row custom-row">
              <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['poshta_shippingCost', ])"></div>
                <DefaultInput
                        :label="$t('poshta_shippingCost.localization_value.value')"
                        :type="'text'"
                        v-model="UkrP.data.deliveryPrice"
                />
              </div>
              <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['poshta_trackingNumber', ])"></div>
                <DefaultInput
                        :label="$t('poshta_trackingNumber.localization_value.value')"
                        :type="'text'"
                        v-model="UkrP.data.trackingNumber"
                />
              </div>
            </div>
          </template>

        </template>
        <template slot="footer">
          <div class="order-create__footer">

            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate(['common_cancel', 'common_Previous'])"></div>
              <router-link :to="$store.getters.GET_PATHS.ukrposhta"
                           class="site-link site-link--alt"
                           v-if="activeStep == 1"
              >
                {{ $t('common_cancel.localization_value.value') }}
              </router-link>
              <span class="site-link site-link--alt"
                    @click="prevPageHandler()"
                    v-if="activeStep != 1"
              >
                {{ $t('common_Previous.localization_value.value') }}
              </span>
            </div>


            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate(['common_SaveInDrafts', 'common_drafts', 'common_savingInDrafts', 'poshta_BuyLabel'])"></div>
              <MainButton
                  class="order-create__footer-btn-i secondary wfc"
                  v-if="activeStep == 5 || isAdmin"
                  :value="$t('common_SaveInDrafts.localization_value.value')"
                  :tooltip="true"
                  @click.native="$emit('edit', true)"
              >
                <template slot="tooltip">
                  <p>
                    <b>{{ $t('common_drafts.localization_value.value') }}</b>
                  </p>
                  <p>
                    {{ $t('common_savingInDrafts.localization_value.value') }}
                  </p>
                </template>
              </MainButton>

              <MainButton
                  class="order-create__footer-btn-i"
                  :value="$t('common_Next.localization_value.value')"
                  @click.native="nextPageHandler()"
                  v-if="activeStep !== progressStepsSetting.length && $store.getters.getIsAdminRights === 'user'"
              />

              <MainButton
                  v-else
                  class="order-create__footer-btn-i wfc"
                  :value="$t('poshta_BuyLabel.localization_value.value')"
                  @click.native="$emit('getDeliveryPrice')"
              />
            </div>

          </div>
        </template>
      </CardRightBlock>

    </div>

  </div>
</template>

<script>
import MainButton from "../../../../../../components/UI/buttons/MainButton/MainButton";
import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
import ProgressSteps from "../../../../../UI/progress/ProgressSteps/ProgressSteps.vue";
import StepOne from "../../components/StepOne/StepOne";
import StepTwo from "../../components/StepTwo/StepTwo";
import StepThree from "../../components/StepThree/StepThree";
import StepFour from "../../components/StepFour/StepFour";
import {tabsMixins} from "../../../../../../mixins/creationPageMixins/tabsMixins";
import UserSelect from "../../../../../coreComponents/UserSelect/UserSelect";
import StepFive from "../../components/StepFive/StepFive";
import {ukrposhtaOrdersMixins} from "../../../../../../mixins/internalOrdersMixins/ukrposhtaOrdersMixin";
import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock";
import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock";
import {vatNumberMixin} from "../../../../../../mixins/commonMixins/vatNumberMixin";
import {orderCurrencyMixin} from "../../../../../../mixins/commonMixins/orderCurrencyMixin";
import {hsCodeMixin} from "../../../../../../mixins/commonMixins/hsCodeMixin";
import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
import {INTERNAL_STATUSES} from "../../../../../../staticData/staticVariables";

export default {
  name: "UkrposhtaEditingPage",

  components: {
    DefaultInput,
    StepFive,
    CardLeftBlock,
    MainButton,
    CardRightBlock,
    LinkBack,
    ProgressSteps,
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    UserSelect,
  },

  mixins: [
    tabsMixins,
    ukrposhtaOrdersMixins,
    vatNumberMixin,
    hsCodeMixin,
    orderCurrencyMixin,
  ],

  props: {
    UkrP: Object,
    packingTypes: Object,
    categoryTypes: Object,
    selectedCountry: {Object, String},
  },

  watch: {
    selectedCountry: function(country){
      this.checkCountryData(country)

    },
  },

  data() {
    return {
      payOrderPopup: false,
      progressStepsSetting: [
        this.$t('poshta_sendingDetails.localization_value.value'),
        this.$t('poshta_recipient.localization_value.value'),
        this.$t('poshta_shippingDetails.localization_value.value'),
        this.$t('poshta_packagingShipping.localization_value.value'),
        this.$t('poshta_confirmation.localization_value.value'),
      ],
      activeStep: 1,

      showingUpc: false,

      options: [{}],
    }
  },

  mounted() {
    this.checkCountryData(this.selectedCountry)
  },

  methods: {
    checkCountryData(country) {
      this.$store.dispatch('getCountryDataById', country.id).then(() => {
        let countryWithCountryGroups = this.$store.getters.getCountryGroup

        this.UkrP.setOrderCurrency(this.getOrderCurrencyByCountry(countryWithCountryGroups))
        // this.UkrP.setVisibleHSCode(this.getHSCodePermissionsByCountry(countryWithCountryGroups))

        // hs code for all countries
        this.UkrP.setVisibleHSCode(true)

        /**
         * Visible Vat Number if order status "copy"
         */
        if(this.UkrP.data.status === INTERNAL_STATUSES.COPY.id){
          this.UkrP.setVisibleAndIsVatNumber(this.getVatNumberPermissionsByCountry(countryWithCountryGroups))
        } else {
          this.UkrP.setIsVatNumberByCountry(this.getVatNumberPermissionsByCountry(countryWithCountryGroups))
        }

        this.$emit('changeRecipientCountry')
      }).catch(error => this.createErrorLog(error))
    },

    changePayOrderPopup(val) {
      this.payOrderPopup = val
    },

    // nextPageHandler() {
    //
    //   let valid = true
    //
    //   switch (this.activeStep) {
    //     case 1:
    //       valid = this.UkrP.firstValidation()
    //       break
    //     case 2:
    //       valid = this.UkrP.secondValidation()
    //       break
    //     case 3:
    //       valid = this.UkrP.thirdValidation()
    //       break
    //     case 4:
    //       valid = this.UkrP.fourthValidation()
    //       break
    //   }
    //
    //   if (valid)
    //     if (this.activeStep < this.progressStepsSetting.length) this.activeStep = this.activeStep + 1
    // },

  }
}
</script>

<style lang="scss">

.product-progress-steps {
  max-width: 625px;
}

</style>
