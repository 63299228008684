<template>

  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <DefaultCheckbox
                class="mt-1"
                :selectedNow="selectedNow"
                :dataValue="item.id"
        />
        <div>
          <router-link
              class="table-card__item-number"
              :to="$store.getters.GET_PATHS.ukrposhta + '/show/' + item.id"
          >
            #{{item.id}}
          </router-link>
        </div>
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.created_at | moment("DD MMM, YYYY") }}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('poshta_Created.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </div>
          </div>

          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('poshta_Shipping.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item.delivery_price"
              />
            </div>
          </div>

          <div class="table-card__item-col custom-col" v-if="isAdmin">
            <div class="table-card__item-label">
              {{$t('common_user.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <TableUserColumn
                      :item="item"
                      :mobile="true"
              />
            </div>
          </div>

          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('poshta_PayPalTransaction.localization_value.value')}}
            </div>
            <div class="table-card__item-info d-flex align-items-center">
              <ValueHelper
                      :deep="'payment_transaction.transaction_id'"
                      :value="item"
              />
            </div>
          </div>

          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('poshta_trackingNumber.localization_value.value')}}
            </div>
            <div class="table-card__item-info d-flex align-items-center">
              <div class="d-flex align-items-center">
                <UkrposhtaBtn v-if="item.tracking_number" class="mr-1"/>
                <ValueHelper
                        :value="item.tracking_number"
                />
              </div>
            </div>
          </div>

          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('poshta_itemNames.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item.products[0].name"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('poshta_Shipped.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ item.shipped_at | moment("DD MMM, YYYY") }}
            </div>
          </div>

        </div>
        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i"
               v-if="(INTERNAL_STATUSES.COMPLETE.id !== item.order_internal_status_id || isAdmin)"
          >
            <LinkButton
                    :value="$t('common_edit.localization_value.value')"
                    :type="'edit'"
                    :link="$store.getters.GET_PATHS.ukrposhta + '/' + item.id"
            />
          </div>
          <div class="table-card__item-btn-i"
               v-if="INTERNAL_STATUSES.COMPLETE.id !== item.order_internal_status_id || isAdmin"
          >
            <LinkButton
                    :value="$t('common_delete.localization_value.value')"
                    :type="'delete'"
                    @click.native="$emit('removeOrder')"
            />
          </div>
          <div class="table-card__item-btn-i"
          >
            <LinkButton
                    :value="$t('common_copy.localization_value.value')"
                    :type="'delete'"
                    @click.native="$emit('copyOrder')"
            />
          </div>
          <div class="table-card__item-btn-i"
               v-if="INTERNAL_STATUSES.COMPLETE.id !== item.order_internal_status_id"
          >
            <LinkButton
                    :value="$t('common_buyLabel.localization_value.value')"
                    :type="'dog'"
                    @click.native="$emit('tableCreateLabel')"
            />
          </div>
          <div class="table-card__item-btn-i"
               v-if="INTERNAL_STATUSES.COMPLETE.id === item.order_internal_status_id"
          >
            <LinkButton
                    :value="$t('common_GetLabel.localization_value.value')"
                    :type="'docs'"
                    :link="$store.getters.GET_PATHS.getPdfFile + '/' + getUkrposhtaLabelUUID(item.file_entries)"
                    :target="'_blank'"
            />
          </div>
          <div class="table-card__item-btn-i"
               v-if="item.group"
          >
            <LinkButton
                    :link="$store.getters.GET_PATHS.getPdfFile + '/' + getUkrposhtaF103AUUID(item.group.file_entries)"
                    :target="'_blank'"
                    :value="$t('common_GetF103A.localization_value.value')"
                    :type="'docs'"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import UkrposhtaBtn from "../../../../../../../coreComponents/Buttons/UkrposhtaBtn/UkrposhtaBtn";
  import {INTERNAL_STATUSES} from "../../../../../../../../staticData/staticVariables";
  import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import {ukrposhtaOrdersTableMixins} from "../../../../../../../../mixins/internalOrdersMixins/ukrposhtaOrdersMixin";
  import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";

  export default {
    name: "UkrposhtaTableMobile",
    components: {DefaultCheckbox, TableUserColumn, UkrposhtaBtn, ValueHelper, LinkButton},
    props: {
      item: Object,
      selectedNow: Boolean,
    },

    mixins: [ukrposhtaOrdersTableMixins],

    data() {
      return {
        INTERNAL_STATUSES: INTERNAL_STATUSES,
        show: false,
      }
    }
  }
</script>

<style scoped>

</style>
