<template>
  <div class="order-create__section">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['packaging_Packaging', ])"></div>
      {{$t('packaging_Packaging.localization_value.value')}}
    </div>


    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col">
        <div class="order-create__toggle-block toggle-block small-size">
          <div class="toggle-block__type">
            <div class="toggle-block__type-list">
              <div class="toggle-block__type-item d-flex mb-2"
                   v-for="(item, index) in packingTypes"
                   :key="index"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit"
                     v-if="item.tooltip"
                     @click="editTranslate([item.label, item.tooltip.name, item.tooltip.description])">
                </div>
                <div class="admin-edit"
                     v-else
                     @click="editTranslate([item.label])">
                </div>

                <RadioDefault
                        :label="$t(`${item.label}.localization_value.value`)"
                        name="packagingType"
                        class="wfc"
                        :value="item.active"
                        @change.native="$emit('changePackagingType', item)"
                />
                <v-popover
                        v-if="item.tooltip"
                        class="site-tooltip"
                        offset="5"
                        placement="top"
                        trigger="hover"
                >
                  <TooltipBtn/>
                  <template slot="popover">
                    <p>
                      <b>{{$t(`${item.tooltip.name}.localization_value.value`)}}</b>
                    </p>
                    <p>
                      {{$t(`${item.tooltip.description}.localization_value.value`)}}
                    </p>
                  </template>
                </v-popover>
              </div>

            </div>
          </div>


          <template
                  v-for="(item, index) in packingTypes">
            <div class="toggle-block__details"
                 :key="index + 'i2'"
                 v-if="item.active"
            >
              <template v-if="item.size">
                <div class="toggle-block__details-head section-label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['poshta_Size'])"></div>
                  {{$t(`poshta_Size.localization_value.value`)}}
                </div>

                <div class="toggle-block__details-body">
                  <div class="custom-row">
                    <div class="custom-col d-flex mb-2">
                      <div class="mr-3"
                           v-for="(radioItem, radioIndex) in item.sizeType"
                           :key="radioIndex"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate([radioItem.name])"></div>
                        <RadioDefault

                                class="mb-2"
                                :label="$t(`${radioItem.name}.localization_value.value`)"
                                :name="item.name"
                                :value="radioItem.active"
                                @change.native="$emit('changePackingSize', {item, radioItem})"
                        />
                      </div>

                    </div>
                  </div>
                </div>
              </template>


              <template v-if="item.inside">
                <div class="toggle-block__details-head section-label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['poshta_Inside'])"></div>
                  {{$t(`poshta_Inside.localization_value.value`)}}
                </div>

                <div class="toggle-block__details-body">
                  <div class="custom-row">
                    <div class="custom-col d-flex mb-2">
                      <div class="mr-3"
                           v-for="(radioItem, radioIndex) in item.insideType"
                           :key="radioIndex"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate([radioItem.name])"></div>
                        <RadioDefault
                                class="mb-2"
                                :label="$t(`${radioItem.name}.localization_value.value`)"
                                :name="item.name"
                                :value="radioItem.active"
                                @change.native="$emit('changePackingInside', {item, radioItem})"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template v-if="!checkCheckboxOptions(item)">
                <div class="toggle-block__details-head section-label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['poshta_Details'])"></div>
                  {{$t(`poshta_NoChoice.localization_value.value`)}}
                </div>
              </template>

              <template v-if="checkCheckboxOptions(item)">
                <div class="toggle-block__details-head section-label"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['poshta_Details'])"></div>
                  {{$t(`poshta_Details.localization_value.value`)}}
                </div>
                <div class="toggle-block__details-body">
                  <div class="custom-row">
                    <div class="custom-col flex-column-reverse mb-2">

                      <template v-for="(checkItem, checkIndex) in item.options">
                        <div :key="checkIndex"
                             v-if="checkItem.visible"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate([checkItem.label])"></div>
                          <DefaultCheckbox
                                  :label="$t(`${checkItem.label}.localization_value.value`)"
                                  class="mb-3"
                                  :value="checkItem.active"
                                  @change.native="$emit('changeCheckItem', {item, checkItem})"
                          />

                          <div class="mb-3"
                               v-if="_.has(checkItem, 'inputShow') && checkItem.inputShow && checkItem.active"
                               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                            <div class="admin-edit" @click="editTranslate(['poshta_declaredPrice', ])"></div>
                            <InputSum
                                    class="w-50"
                                    :label="$t('poshta_declaredPrice.localization_value.value')"
                                    :icoType="'dollar'"
                                    :numeric="true"
                                    :placeholder="'0'"
                                    v-bind:class="{'ui-no-valid': UkrP.validation.declaredPrice}"
                                    :errorTxt="$t(`${UkrP.validationTranslate.declaredPrice}.localization_value.value`)"
                                    :error="UkrP.validation.declaredPrice"
                                    v-model="UkrP.data.declaredPrice"
                            />
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </template>

        </div>
      </div>
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--50 custom-col--md-100 mb-0">
        <div class="custom-row">
          <div class="custom-col">
            <div class="order-create__section-label section-label mb-3"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['poshta_SendType'])"></div>
              {{$t(`poshta_SendType.localization_value.value`)}}
            </div>
            <div class="custom-row">
              <div class="order-create__col custom-col">
                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate([categoryTypes.sale_of_goods.label])"></div>
                  <RadioDefault
                          class="mb-2"
                          v-if="categoryTypes.sale_of_goods.visible"
                          :label="$t(`${categoryTypes.sale_of_goods.label}.localization_value.value`)"
                          :name="categoryTypes.sale_of_goods.name"
                          :value="categoryTypes.sale_of_goods.active"
                          @change.native="$emit('changeCategoryType', categoryTypes.sale_of_goods)"
                  />
                </div>
                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate([categoryTypes.gift.label])"></div>
                  <RadioDefault
                          class="mb-2"
                          :label="$t(`${categoryTypes.gift.label}.localization_value.value`)"
                          :name="categoryTypes.gift.name"
                          :value="categoryTypes.gift.active"
                          @change.native="$emit('changeCategoryType', categoryTypes.gift)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
  import {mixinDetictingMobile} from "../../../../../../mixins/mobileFunctions";
  import TooltipBtn from "../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  import RadioDefault from "../../../../../UI/radiobuttons/RadioDefault/RadioDefault";
  import DefaultCheckbox from "../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import {radioButtonsFunc} from "../../../../../../mixins/creationPageMixins/radioButtonsFunc";
  import InputSum from "../../../../../UI/inputs/InputSum/InputSum";
  // import {
  //   UkrposhtaCategoryTypes,
  //   UkrposhtaCheckboxItems,
  //   UkrposhtaPackageTypes
  // } from "../../../../../../staticData/ukrposhtaStatic";

  export default {
    name: "StepFour",

    components: {
      InputSum,
      TooltipBtn,
      RadioDefault,
      DefaultCheckbox,
    },

    mixins: [mixinDetictingMobile, radioButtonsFunc],

    props: {
      UkrP: Object,
      packingTypes: Object,
      categoryTypes: Object,
    },

    data() {
      return {

        // packingTypes: UkrposhtaPackageTypes,

        // checkboxItems: UkrposhtaCheckboxItems,

        // categoryTypes: UkrposhtaCategoryTypes,


        // bulky
        // useDeclaredPrice
        // useReceiptNotification
        // useHandOverPersonally
        // useDeliveryNotification

        letterSize: {
          a5: 'A5',
          a6: 'A6',
          none: 'none',
        },

        smallBagSize: {
          a5: 'A5',
          a6: 'A6',
          a100: '100*100',
        },

        packagingInsideRadio: {
          product: 'Product',
          printed: 'Printed',
        },

      }
    },

    methods: {

      checkCheckboxOptions(item) {
        if(Object.keys(item.options).length === 1 && !item.options?.use_receipt_notification.visible)
          return false

        if(Object.keys(item.options).length > 0)
          return true

        return false
      },

      // changePacking(name) {
      // 	this.skipRadioChecked(this.packingTypes, 'checked')
      //   // this.packingTypes[name].checked = true
      //   name.checked = true
      //   this.UkrP.data.packagingType = name.value
      // }
    }

  }
</script>

<style scoped lang="scss">
  @import "../../../../../../scss/mixins/mixins";

  .toggle-block.small-size .toggle-block__details {
    width: 100%;
    max-width: 358px;
    min-height: 310px;

    @include for-768 {
      margin-top: 20px;
      min-height: initial;
    }
  }

  .toggle-block__details-body {
    height: initial;
  }
</style>
