import { render, staticRenderFns } from "./UkrposhtaCreation.vue?vue&type=template&id=37ceb99a&scoped=true"
import script from "./UkrposhtaCreation.vue?vue&type=script&lang=js"
export * from "./UkrposhtaCreation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37ceb99a",
  null
  
)

export default component.exports