<template>
  <div class="order-create__section">
    <div class="order-create__title-horizontal"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta_sendingDetails',])"></div>
      <TitleHorizontal
              :value="$t('poshta_sendingDetails.localization_value.value')"
              :rightBtn="showEdit"
              :rightBtnType="'edit'"
              :rightBtnText="$t('common_edit.localization_value.value')"
              @rightBtnClick="$emit('changeStep', 0)"
      />
    </div>

    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta_PersonalInformation',])"></div>
      {{$t('poshta_PersonalInformation.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col">
        <div class="order-create__table block-table">
          <div class="block-table__row">
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['poshta_contactName',])"></div>
                {{$t('poshta_contactName.localization_value.value')}}
              </div>
              <div class="block-table__content word-break">
                {{PersonalInformation.information.personalName}}
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_phone',])"></div>
                {{$t('common_phone.localization_value.value')}}
              </div>
              <div class="block-table__content">
                {{PersonalInformation.information.personalPhone}}
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_email',])"></div>
                {{$t('common_email.localization_value.value')}}
              </div>
              <div class="block-table__content">
                {{PersonalInformation.information.personalEmail}}
              </div>
            </div>
          </div>

          <div class="order-create__section-label section-label mt-2"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['poshta_SenderAddress'])"></div>
            {{$t('poshta_SenderAddress.localization_value.value')}}
          </div>


          <div class="block-table__row">
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_address',])"></div>
                {{$t('common_address.localization_value.value')}}
              </div>
              <div class="block-table__content">
                {{DeliveryAddress.delivery.address}}
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_region',])"></div>
                {{$t('common_region.localization_value.value')}}
              </div>
              <div class="block-table__content">
                {{DeliveryAddress.delivery.region}}
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_country',])"></div>
                {{$t('common_country.localization_value.value')}}
              </div>
              <div class="block-table__content">
                {{DeliveryAddress.delivery.country.label}}
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_city',])"></div>
                {{$t('common_city.localization_value.value')}}
              </div>
              <div class="block-table__content">
                {{DeliveryAddress.delivery.city}}
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_zipCode',])"></div>
                {{$t('common_zipCode.localization_value.value')}}
              </div>
              <div class="block-table__content">
                {{DeliveryAddress.delivery.zip}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="order-create__title-horizontal"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['product_ProductDetails',])"></div>
      <TitleHorizontal
              :value="$t('product_ProductDetails.localization_value.value')"
              :rightBtn="showEdit"
              :rightBtnType="'edit'"
              :rightBtnText="$t('common_edit.localization_value.value')"
              @rightBtnClick="$emit('changeStep', 1)"
      />
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col">


        <div class="block-table__name block-table__name--mobile  mb-4"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['poshta_products',])"></div>
          {{$t('poshta_products.localization_value.value')}}
          <!--          Products-->
        </div>

        <div class="site-table-wrap table-small"
             v-if="!isMobileFunc()"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}"
        >
          <div class="admin-edit" @click="editTranslate([
                'poshta_productName',
                'poshta_qTy',
                'poshta_perOne',
                'common_total'
              ])"></div>
          <table class="site-table table-small">

            <thead>
            <tr>
              <th width="100%">
                {{$t('poshta_productName.localization_value.value')}}
              </th>
              <th class="white-space-line" align="center">
                {{$t('poshta_qTy.localization_value.value')}}
              </th>
              <th class="white-space-line" align="center">
                {{$t('poshta_perOne.localization_value.value')}},
                <span v-if="UkrP.data.orderCurrency === 'dollar'">$</span>
                <span v-if="UkrP.data.orderCurrency === 'euro'">€</span>
              </th>
              <th class="white-space-line" align="center">
                {{$t('common_total.localization_value.value')}},
                <span v-if="UkrP.data.orderCurrency === 'dollar'">$</span>
                <span v-if="UkrP.data.orderCurrency === 'euro'">€</span>
              </th>
            </tr>
            </thead>
            <tbody>

            <tr
                    v-for="(product, prodIndex) in UkrP.data.UkrposhtaProduct"
                    :key="prodIndex"
            >
              <td width="100%">
                {{product.data.productObject.name}}
              </td>
              <td align="right">
                {{product.data.count}}
              </td>
              <td align="right">
                {{product.data.price}}
              </td>
              <td align="right">
                {{product.data.totalSum}}
              </td>
            </tr>
            </tbody>
          </table>

        </div>

        <div class="site-table-mobile"
             v-if="isMobileFunc()"
        >
          <div class="site-table-mobile__inner custom-row">
            <div class="custom-col custom-col--50 custom-col--sm-100">
              <div class="site-table-mobile__item"
                   v-for="(product, prodIndex) in UkrP.data.UkrposhtaProduct"
                   :key="prodIndex">
                <div class="site-table-mobile__head">
                  {{product.data.productObject.name}}
                </div>
                <div class="site-table-mobile__row custom-row">
                  <div class="site-table-mobile__piece custom-col custom-col--33">
                    {{$t('poshta_qTy.localization_value.value')}}
                    <span>
											{{product.data.count}}
										</span>
                  </div>
                  <div class="site-table-mobile__piece custom-col custom-col--33">
                    {{$t('poshta_perOne.localization_value.value')}}, $
                    <span>
											{{product.data.price}}
										</span>
                  </div>
                  <div class="site-table-mobile__piece custom-col custom-col--33">
                    {{$t('common_total.localization_value.value')}}, $
                    <span>
											{{product.data.totalSum}}
										</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col mb-0">
        <div class="order-create__table">
          <div class="block-table__name"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['poshta_shippingInformation',])"></div>
            {{$t('poshta_shippingInformation.localization_value.value')}}
          </div>

          <div class="custom-row">
            <div class="custom-col custom-col--16 custom-col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['packaging_Packaging'])"></div>
                {{$t('packaging_Packaging.localization_value.value')}}
              </div>
              <div class="block-table__content">
                {{ UkrP.data.Packaging.packaging.name.package_name }}
              </div>
            </div>
            <div class="custom-col custom-col--16 custom-col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_weightKg',])"></div>
                {{$t('common_weightKg.localization_value.value')}}
              </div>
              <div class="block-table__content">
                {{ UkrP.data.Packaging.packaging.weight }}
              </div>
            </div>
            <div class="custom-col custom-col--16 custom-col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_heightCm',])"></div>
                {{$t('common_heightCm.localization_value.value')}}
              </div>
              <div class="block-table__content">
                {{ UkrP.data.Packaging.packaging.height }}
              </div>
            </div>
            <div class="custom-col custom-col--16 custom-col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_width, cm',])"></div>
                {{$t('common_width, cm.localization_value.value')}}
              </div>
              <div class="block-table__content">
                {{ UkrP.data.Packaging.packaging.width }}
              </div>
            </div>
            <div class="custom-col custom-col--16 custom-col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_lengthCm',])"></div>
                {{$t('common_lengthCm.localization_value.value')}}
              </div>
              <div class="block-table__content">
                {{ UkrP.data.Packaging.packaging["length"] }}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col">
        <div class="order-create__table block-table">
          <div class="block-table__row">
            <div class="block-table__col block-table__col--16">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['poshta_ShippingDate',])"></div>
                {{$t('poshta_ShippingDate.localization_value.value')}}
              </div>
              <div class="block-table__content">
                {{ UkrP.data.shippingDate }}
              </div>
            </div>

            <div class="block-table__col block-table__col--16">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                            'common_vatNumber',
                          ])"></div>
                {{$t('common_vatNumber.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="UkrP.data.vatNumber"
                />
              </div>
            </div>

            <div class="block-table__col block-table__col--16" v-if="!showEdit">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                            'poshta_trackingNumber',
                          ])"></div>
                {{$t('poshta_trackingNumber.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="UkrP.data.trackingNumber"
                />
              </div>
            </div>

            <div class="block-table__col block-table__col--16" v-if="!showEdit">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                            'poshta_Shipping',
                          ])"></div>
                {{$t('poshta_Shipping.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                        :value="UkrP.data.deliveryPrice"
                />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


    <div class="order-create__title-horizontal">
      <TitleHorizontal
              :value="'Packaging & Shipping'"
              :rightBtn="showEdit"
              :rightBtnType="'edit'"
              :rightBtnText="$t('common_edit.localization_value.value')"
              @rightBtnClick="$emit('changeStep', 2)"
      />
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col">
        <div
                v-for="(item, index) in packingTypes"
                :key="index">
          <template
                  v-if="item.active">
            <div class="d-flex">
              <RadioDefault
                      :label="$t(`${item.label}.localization_value.value`)"
                      :value="true"
                      :disabled="true"
                      class="wfc"
              />
              <v-popover
                      v-if="item.tooltip"
                      class="site-tooltip"
                      offset="5"
                      placement="top"
                      trigger="hover"
              >
                <TooltipBtn/>
                <template slot="popover">
                  <p>
                    <b>{{$t(`${item.tooltip.name}.localization_value.value`)}}</b>
                  </p>
                  <p>
                    {{$t(`${item.tooltip.description}.localization_value.value`)}}
                  </p>
                </template>
              </v-popover>


              <div class="block-table__content d-flex align-items-center"
                   v-if="item.size"
              >
                <div class="d-flex align-items-center ml-2 mr-2">
                  <ArrowRightBrownIco/>
                </div>
                <template v-for="(radioItem, radioIndex) in item.sizeType">
                  <div :key="radioIndex"
                       v-if="radioItem.active"
                  >
                    {{$t(`${radioItem.name}.localization_value.value`)}}
                  </div>
                </template>

              </div>

              <div class="block-table__content d-flex align-items-center"
                   v-if="item.inside"
              >
                <div class="d-flex align-items-center ml-2 mr-2">
                  <ArrowRightBrownIco/>
                </div>
                <template v-for="(radioItem, radioIndex) in item.insideType">
                  <div :key="radioIndex"
                       v-if="radioItem.active"
                  >
                    {{$t(`${radioItem.name}.localization_value.value`)}}
                  </div>
                </template>

              </div>
            </div>

            <div class="mt-2"
                 v-if="Object.keys(item.options).length > 0"
            >
              <div
                      v-for="(checkItem, checkIndex) in item.options"
                      :key="checkIndex"
              >
                <DefaultCheckbox
                        v-if="checkItem.active"
                        class="mb-3"
                        :label="$t(`${checkItem.label}.localization_value.value`)"
                        :disabled="true"
                        :value="checkItem.active"
                />
              </div>

            </div>

          </template>
        </div>
      </div>
    </div>


    <div class="order-create__section-label section-label mb-3">
      Shipping
    </div>
    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col">
        <RadioDefault
                class="mb-2"
                v-if="categoryTypes.gift.active"
                :label="$t(`${categoryTypes.gift.label}.localization_value.value`)"
                :name="categoryTypes.gift.name"
                :value="categoryTypes.gift.active"
                @change.native="$emit('changeCategoryType', categoryTypes.gift)"
                :disabled="true"
        />
        <RadioDefault
                class="mb-2"
                v-if="categoryTypes.sale_of_goods.active"
                :label="$t(`${categoryTypes.sale_of_goods.label}.localization_value.value`)"
                :name="categoryTypes.sale_of_goods.name"
                :value="categoryTypes.sale_of_goods.active"
                @change.native="$emit('changeCategoryType', categoryTypes.sale_of_goods)"
                :disabled="true"
        />
      </div>
    </div>

    <div class="order-create__title-horizontal"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta_recipient',])"></div>
      <TitleHorizontal
              :value="$t('poshta_recipient.localization_value.value')"
              :rightBtn="showEdit"
              :rightBtnType="'edit'"
              :rightBtnText="$t('common_edit.localization_value.value')"
              @rightBtnClick="$emit('changeStep', 3)"
      />
    </div>
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta_PersonalInformation',])"></div>
      {{$t('poshta_PersonalInformation.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col">
        <div class="order-create__table block-table">
          <div class="block-table__row">
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['poshta_contactName',])"></div>
                {{$t('poshta_contactName.localization_value.value')}}
              </div>
              <div class="block-table__content word-break">
                {{ UkrP.data.RecipientInformation.information.personalName }}
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_phone',])"></div>
                {{$t('common_phone.localization_value.value')}}
              </div>
              <div class="block-table__content">
                {{ UkrP.data.RecipientInformation.information.personalPhone }}
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_email',])"></div>
                {{$t('common_email.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="UkrP.data.RecipientInformation.information.personalEmail"
                />
              </div>
            </div>
          </div>

          <div class="order-create__section-label section-label mt-2"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['poshta_deliveryAddress',])"></div>
            {{$t('poshta_deliveryAddress.localization_value.value')}}
          </div>

          <div class="block-table__row">
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_address',])"></div>
                {{$t('common_address.localization_value.value')}}
              </div>
              <div class="block-table__content">
                {{ UkrP.data.RecipientDeliveryAddress.delivery.address }}
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_city',])"></div>
                {{$t('common_city.localization_value.value')}}
              </div>
              <div class="block-table__content">
                {{ UkrP.data.RecipientDeliveryAddress.delivery.city }}
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_region',])"></div>
                {{$t('common_region.localization_value.value')}}
              </div>
              <div class="block-table__content">
                {{ UkrP.data.RecipientDeliveryAddress.delivery.region }}
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_country',])"></div>
                {{$t('common_country.localization_value.value')}}
              </div>
              <div class="block-table__content">
                {{ UkrP.data.RecipientDeliveryAddress.delivery.country.label }}
              </div>
            </div>
            <div class="block-table__col block-table__col--20 block-table__col--sm-50">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_zipCode',])"></div>
                {{$t('common_zipCode.localization_value.value')}}
              </div>
              <div class="block-table__content">
                {{ UkrP.data.RecipientDeliveryAddress.delivery.zip }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>


  import TooltipBtn from "../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  import {VPopover} from "v-tooltip";
  import RadioDefault from "../../../../../UI/radiobuttons/RadioDefault/RadioDefault";
  import DefaultCheckbox from "../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import TitleHorizontal from "../../../../../coreComponents/TitleHorizontal/TitleHorizontal";
  import {mixinDetictingMobile} from "../../../../../../mixins/mobileFunctions";
  import ArrowRightBrownIco from '../../../../../../../public/img/UI-group/arrow-right-brown.svg?inline'
  import ValueHelper from "../../../../../coreComponents/ValueHelper/ValueHelper";

  export default {
    name: "StepFive",

    components: {
      ValueHelper,
      TooltipBtn,
      VPopover,
      RadioDefault,
      DefaultCheckbox,
      TitleHorizontal,
      ArrowRightBrownIco,
    },

    mixins: [mixinDetictingMobile],

    props: {
      UkrP: Object,
      PersonalInformation: Object,
      DeliveryAddress: Object,
      packingTypes: Object,
      categoryTypes: Object,
      showEdit: {
        type: Boolean,
        default: true,
      },
    },

    data() {
      return {
        options: [{}],

        usps: 'usps',
        fedex: 'fedex',
        tooltipAddProductActive: true,
        comment: 'Happy New Year! Wish you all best, by darling friend!',
      }
    },

    methods: {


      addressTypeeChange(index) {
        console.log(index);
      }
    },
  }
</script>

<style scoped>

</style>

