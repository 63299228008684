import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {PersonalInformation} from "../../../globalModels/PersonalInformation";
import {Delivery} from "../../../globalModels/Delivery";
import {User} from "../../../globalModels/User";
import _ from 'lodash'
import {SimpleProduct} from "../../../globalModels/SimpleProduct";
import {Packaging} from "../../../globalModels/Packaging";
import {UKRPOSHTA_DELIVERY_SERVICE} from "../../../../staticData/staticVariables";

const moment = require('moment')

export function Ukrposhta() {

  this.checkValid = checkValid

  this.data = {
    User: new User(),

    type: '',
    id: '',
    status: '',

    paymentTransactionId: '',
    typeTransaction: '',
    typesTransaction: {
      order: 'order',
      payment: 'payment',
    },

    trackingNumber: '',
    transactionNumber: '',

    PersonalInformation: new PersonalInformation(),
    DeliveryAddress: new Delivery(),

    UkrposhtaProduct: [new SimpleProduct()],
    visibleHSCode: false,

    Packaging: new Packaging(),

    RecipientInformation: new PersonalInformation(),
    RecipientDeliveryAddress: new Delivery(),

    shippingDate: '',
    shippingCost: '',

    deliveryPrice: '',

    packagingType: 'parcel',
    packagingTypes: {
      parcel: 'parcel',
      smallBag: 'smallBag',
      recommendedLetter: 'recommendedLetter',
      parcelPost: 'parcelPost',
    },
    packagingSize: '',
    packagingInside: '',

    checkboxLargeMark: false,
    checkboxUsePriceUAH: false,
    checkboxPersonally: false,
    checkboxNotify: false,
    checkboxShipDirectFlight: false,

    shippingLabel: false,
    shipping: false,

    declaredPrice: '',

    vatNumber: '',
    visibleVatNumber: true,
    isVatNumberByCountry: false,

    orderCurrency: 'dollar',

  }

  this.validation = {
    shippingDate: false,
    vatNumber: false,
    declaredPrice: false,
  }

  this.validationTranslate = {
    shippingDate: '',
    vatNumber: '',
    declaredPrice: '',
  }

  this.validationTxt = {
    shippingDate: false,
    vatNumber: false,
    declaredPrice: false,
  }

}


/*****************   SETTERS   ******************/

Ukrposhta.prototype.setType = function (val) {
  this.data.type = val;
}

Ukrposhta.prototype.setId = function (val) {
  this.data.id = val;
}

Ukrposhta.prototype.setVatNumber = function (val) {
  this.data.vatNumber = val
}

Ukrposhta.prototype.setVisibleVatNumber = function (val) {
  this.data.visibleVatNumber = val
}

Ukrposhta.prototype.setIsVatNumberByCountry = function (val) {
  this.data.isVatNumberByCountry = val
}


Ukrposhta.prototype.setUserData = function (user) {

  if(!user?.id) return

  let userInformation
  let data = this.data

  if(user?.contacts){
    userInformation = _.first(user?.contacts)
  } else {
    userInformation = user?.user_personal_contact
  }

  data.User.setUser(user)

  this.writingDataCurrentUser(userInformation)

}

Ukrposhta.prototype.writingDataCurrentUser = function (userInformation) {
  let data = this.data

  data.PersonalInformation.setPersonalItem({
    personalName: userInformation.user_full_name,
    personalPhone: userInformation.phone.replace(/[^\d]/g, ''),
    personalEmail: userInformation.email,
  })

  data.DeliveryAddress.setDeliveryItem({
    address: userInformation.address,
    city: userInformation.city,
    region: userInformation.region.name,
    country: {
      id: userInformation.country.id,
      label: userInformation.country.name,
    },
    zip: userInformation.zip
  })
}


Ukrposhta.prototype.setUkrposhtaItem = function (val) {

  let data = this.data

  if (val.user) {
    data.User.setUser(val.user)
  }

  if (val.products) {

    let simpleProduct = []

    val.products.map((item) => {
      let itemSimpleProduct = new SimpleProduct()
      itemSimpleProduct.setItem(item)

      simpleProduct.push(itemSimpleProduct)
    })

    this.data.UkrposhtaProduct = simpleProduct
  }

  data.status = val.order_internal_status_id

  data.trackingNumber = val.tracking_number ? val.tracking_number : ''
  data.transactionNumber = val.payment_transaction ? val.payment_transaction.transaction_id : ''
  data.shippingCost = val.shipping_cost ? val.shipping_cost : ''

  data.declaredPrice = val.declared_value ? val.declared_value : ''

  data.deliveryPrice = val.delivery_price ? val.delivery_price : ''

  this.setId(val.id)


  data.PersonalInformation.setPersonalItem({
    personalName: val.sender_full_name,
    personalPhone: val.sender_phone ? val.sender_phone.replace(/[^\d]/g, '') : '',
    personalEmail: val.sender_email,
  })

  data.DeliveryAddress.setDeliveryItem({
    address: val.sender_address,
    city: val.sender_city,
    region: val.sender_state,
    country: val.sender_country ? {
      id: val.sender_country.id,
      label: val.sender_country.name,
    } : '',
    zip: val.sender_zip,
  })


  this.setShippingDate(
    val["shipped_at"] ? moment(val["shipped_at"]).format('YYYY-MM-DD') : ''
  )

  if(val.vat_number) {
    this.setVatNumber(val.vat_number)
    this.setIsVatNumberByCountry(true)
    this.setVisibleVatNumber(true)
  } else {
    this.setIsVatNumberByCountry(false)
    this.setVisibleVatNumber(false)
  }

  data.Packaging.setPackagingItem({
    weight: parseFloat(val.weight).toFixed(2),
    height: parseFloat(val.height).toFixed(2),
    width: parseFloat(val.width).toFixed(2),
    length: parseFloat(val['length']).toFixed(2),
  })

  data.RecipientInformation.setPersonalItem({
    personalName: val.recipient_full_name,
    personalPhone: val.recipient_phone ? val.recipient_phone.replace(/[^\d]/g, '') : '',
    personalEmail: val.recipient_email,
  })

  data.RecipientDeliveryAddress.setDeliveryItem({
    address: val.recipient_address,
    city: val.recipient_city,
    region: val.recipient_state,
    country: val.recipient_country ? {
      id: val.recipient_country.id,
      label: val.recipient_country.name,
      code_iso_2: val.recipient_country.code_iso_2,
    } : '',
    zip: val.recipient_zip,
  })

  // this.setShippingCost(val["shipping_cost"] ? val["shipping_cost"] : null)
  // this.setTrackingNumber(val["tracking_number"] ? val["tracking_number"] : null)

}


Ukrposhta.prototype.setPaymentTransactionId = function (val) {
  this.data.paymentTransactionId = val;
}
Ukrposhta.prototype.setTypeTransaction = function (val) {
  this.data.typeTransaction = val;
}


Ukrposhta.prototype.setShippingDate = function (val) {
  this.data.shippingDate = val;
}

Ukrposhta.prototype.setPackagingType = function (val) {
  this.data.packagingType = val;
}

Ukrposhta.prototype.setPackagingTypes = function (val) {
  this.data.packagingTypes = val;
}

Ukrposhta.prototype.setPackagingSize = function (val) {
  this.data.packagingSize = val;
}

Ukrposhta.prototype.setPackagingInside = function (val) {
  this.data.packagingInside = val;
}
Ukrposhta.prototype.setCheckboxLargeMark = function (val) {
  this.data.checkboxLargeMark = val;
}
Ukrposhta.prototype.setCheckboxUsePriceUAH = function (val) {
  this.data.checkboxUsePriceUAH = val;
}
Ukrposhta.prototype.setCheckboxPersonally = function (val) {
  this.data.checkboxPersonally = val;
}
Ukrposhta.prototype.setCheckboxNotify = function (val) {
  this.data.checkboxNotify = val;
}
Ukrposhta.prototype.setCheckboxShipDirectFlight = function (val) {
  this.data.checkboxShipDirectFlight = val;
}
Ukrposhta.prototype.setShippingLabel = function (val) {
  this.data.shippingLabel = val;
}
Ukrposhta.prototype.setShipping = function (val) {
  this.data.shipping = val;
}

Ukrposhta.prototype.setRecipientInformation = function (val) {
  this.data.RecipientInformation.setPersonalItem({
    personalName: val.full_name,
    personalPhone: val.phone ? val.phone.replace(/[^\d]/g, '') : '',
    personalEmail: val.email
  })
}

Ukrposhta.prototype.setRecipientDeliveryAddress = function (val) {
  this.data.RecipientDeliveryAddress.setDeliveryItem({
    address: val.address,
    city: val.city,
    region: val.region,
    country: val.country ? {
      id: val.country.id,
      label: val.country.label,
      code_iso_2: val.country.code_iso_2,
    } : null,
    zip: val.zip
  })
}

Ukrposhta.prototype.setVisibleAndIsVatNumber = function (val) {
  this.data.visibleVatNumber = val
  this.data.isVatNumberByCountry = val
}

Ukrposhta.prototype.setVisibleHSCode = function (val) {
  this.data.visibleHSCode = val
}

Ukrposhta.prototype.setOrderCurrency = function (val) {
  this.data.orderCurrency = val
}

/*****************   GETTERS   ******************/
Ukrposhta.prototype.getType = function () {
  return this.data.type
}

Ukrposhta.prototype.getId = function () {
  return this.data.id
}

Ukrposhta.prototype.getVatNumber = function () {
  return this.data.vatNumber
}

Ukrposhta.prototype.getVisibleVatNumber = function () {
  return this.data.visibleVatNumber
}

Ukrposhta.prototype.getIsVatNumberByCountry = function () {
  return this.data.isVatNumberByCountry
}

Ukrposhta.prototype.getShippingDate = function (){
  return this.data.shippingDate;
}
Ukrposhta.prototype.getPackagingType = function (){
  return this.data.packagingType;
}
Ukrposhta.prototype.getPackagingTypes = function (){
  return this.data.packagingTypes;
}
Ukrposhta.prototype.getPackagingSize = function (){
  return this.data.packagingSize;
}
Ukrposhta.prototype.getPackagingInside = function (){
  return this.data.packagingInside;
}
Ukrposhta.prototype.getCheckboxLargeMark = function (){
  return this.data.checkboxLargeMark;
}
Ukrposhta.prototype.getCheckboxUsePriceUAH = function (){
  return this.data.checkboxUsePriceUAH;
}
Ukrposhta.prototype.getCheckboxPersonally = function (){
  return this.data.checkboxPersonally;
}
Ukrposhta.prototype.getCheckboxNotify = function (){
  return this.data.checkboxNotify;
}
Ukrposhta.prototype.getCheckboxShipDirectFlight = function (){
  return this.data.checkboxShipDirectFlight;
}
Ukrposhta.prototype.getShippingLabel = function (){
  return this.data.shippingLabel;
}
Ukrposhta.prototype.getShipping = function (){
  return this.data.shipping;
}


/*******************   FUNCTIONS   *************/
Ukrposhta.prototype.addUkrposhtaProduct = function() {

  if(this.data.UkrposhtaProduct.length > 3) return

  this.data.UkrposhtaProduct.push(new SimpleProduct())

  return this.data.UkrposhtaProduct.length
}

Ukrposhta.prototype.removeUkrposhtaProduct = function(index) {

  if(this.data.UkrposhtaProduct.length <= 1) return

  this.data.UkrposhtaProduct.splice(index, 1)

  return this.data.UkrposhtaProduct.length
}


/******************  VALIDATIONS  *****************/


Ukrposhta.prototype.vatNumberValidation = function () {

  if(!this.getVisibleVatNumber()) return true

  let validationItems = {
    vatNumber: this.getVatNumber(),
  }

  let validationOptions = {
    vatNumber: {type: ['empty']}
  }

  return this.checkValid(validationItems, validationOptions)
}

Ukrposhta.prototype.firstValidation = function () {

  let data = this.data

  let personalInfoValid = data.PersonalInformation
    .personalInfoPartsValidate({
    personalName: true,
    personalPhone: true,
    personalEmail: true,
  })

  let senderAddressValid = data.DeliveryAddress
    .deliveryInfoPartialValidate({
    address: true,
    city: true,
    region: true,
    country: true,
    zip: true,
  })

  return (personalInfoValid && senderAddressValid)
}

Ukrposhta.prototype.secondValidation = function () {


  let data = this.data

  let personalInfoValid = data.RecipientInformation
    .personalInfoPartsValidate({
      personalName: true,
      personalPhone: true,
    })

  let senderAddressValid = data.RecipientDeliveryAddress
    .deliveryInfoPartialValidate({
      address: true,
      city: true,
      region: true,
      country: true,
      zip: true,
    })

  return (personalInfoValid && senderAddressValid)
}

Ukrposhta.prototype.thirdValidation = function () {

  let data = this.data

  let validationItems = {
    shippingDate: data.shippingDate,
  }

  let validationOptions = {
    shippingDate: {type: ['empty']}
  }

  let ukrposhtaProductValid = true

  data.UkrposhtaProduct.map(item => {
    if(!item.dataValidation({
      productObject: true,
      name: item.data.name !== '',
      count: true,
      price: true,
      nameCyrillic: true,
      hSCode: this.data.visibleHSCode,
      hsCodeLength: {
        min: 6,
        max: 10,
      }
    }))
      ukrposhtaProductValid = false
  })


  let packagingValid = data.Packaging.packagingPartialValidate({
    weight: true,
    height: true,
    width: true,
    packageLength: true,
  })

  let vatNumberValid = this.vatNumberValidation()


  return (this.checkValid(validationItems, validationOptions) &&
    ukrposhtaProductValid && packagingValid && vatNumberValid)

}

Ukrposhta.prototype.fourthValidation = function (packingTypes) {

  if(packingTypes.parcel.active &&
    packingTypes.parcel.options.use_declared_price.active){
    let data = this.data

    let validationItems = {
      declaredPrice: data.declaredPrice,
    }

    let validationOptions = {
      declaredPrice: {type: ['empty', 'zero']}
    }

    return this.checkValid(validationItems, validationOptions)
  }

  return true

}

Ukrposhta.prototype.adminValidation = function (packingTypes) {
  // let data = this.data

  // let validationItems = {
  //   shippingCost: data.shippingCost,
  // }
  //
  // let validationOptions = {
  //   shippingCost: {type: ['numeric']}
  // }

  return (
    // this.checkValid(validationItems, validationOptions) &&
    this.firstValidation() &&
    this.secondValidation() &&
    this.thirdValidation() &&
    this.fourthValidation(packingTypes)
  )
}



/******************  PREPARE DATA  *****************/


Ukrposhta.prototype.prepareData = function (isAdmin = false) {
  let poshta = this.data
  let packaging = this.data.Packaging.preparePackagingItem()

  let personalInfoFrom = this.data.PersonalInformation.preparePersonalInformation()
  let sendersAddressFrom = this.data.DeliveryAddress.delivery
  let personalInfoTo = this.data.RecipientInformation.preparePersonalInformation()
  let sendersAddressTo = this.data.RecipientDeliveryAddress.delivery

  let items = []

  this.data.UkrposhtaProduct.map((item) => {
    items.push({
      'name': item.data.productObject.name,
      'price': item.data.price,
      'quantity': item.data.count,
      'total': item.data.totalSum,
      'name_cyrillic': item.data.nameCyrillic,
      'hs_code': item.data.hSCode,
    })
  })


  let data = {
    "delivery_service_id": UKRPOSHTA_DELIVERY_SERVICE.id,

    "shipped_at": poshta.shippingDate,

    "weight": parseFloat(packaging.weight),
    "length": parseFloat(packaging['length']),
    "width": parseFloat(packaging.width),
    "height": parseFloat(packaging.height),

    "volume_weight": packaging.volumeWeight,
    "volume_general": packaging.volumeGeneral,

    "sender_country_id": sendersAddressFrom.country.id,
    "sender_full_name": personalInfoFrom.personalName,
    "sender_address": sendersAddressFrom.address,
    "sender_state": sendersAddressFrom.region,
    "sender_zip": sendersAddressFrom.zip,
    "sender_city": sendersAddressFrom.city,
    "sender_phone": personalInfoFrom.personalPhone.replace(/[^\d]/g, ''),
    "sender_email": personalInfoFrom.personalEmail,

    "recipient_country_id": sendersAddressTo.country.id,
    "recipient_country": sendersAddressTo.country,
    "recipient_full_name": personalInfoTo.personalName,
    "recipient_address": sendersAddressTo.address,
    "recipient_zip": sendersAddressTo.zip,
    "recipient_city": sendersAddressTo.city,
    "recipient_state": sendersAddressTo.region,
    "recipient_phone": personalInfoTo.personalPhone.replace(/[^\d]/g, ''),
    "recipient_email": personalInfoTo.personalEmail,

    "items": items,

    "user_id": poshta.User.getUserId(),

  }



  if(this.getVisibleVatNumber() && this.getIsVatNumberByCountry()){
    data['vat_number'] = this.getVatNumber()
  } else {
    data['vat_number'] = null
  }

  if(poshta.paymentTransactionId !== '' && poshta.typeTransaction !== ''){
    switch (poshta.typeTransaction) {
      case poshta.typesTransaction.order:
        data['order_external_id'] = poshta.paymentTransactionId
        break
      case poshta.typesTransaction.payment:
        data['payment_transaction_id'] = poshta.paymentTransactionId
        break
    }
  }


  if(isAdmin){
    data["delivery_price"] = poshta.deliveryPrice
    data["tracking_number"] = poshta.trackingNumber
  }


  return data
}
